import React from "react";
import {
    Table,
    TableHeader,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from "../../../../../components/table/tableComponent";
import {tableData} from "../../../../../components/table/tableData";
import {RxPencil2} from "react-icons/rx";
import {GoTrash} from "react-icons/go";
import {IoEyeOutline} from "react-icons/io5";

const TemplatesTable = () => {
    return (
        <div className="overflow-x-auto rounded-md shadow-sm">
            {tableData.map((table, index) => (
                <Table key={index}
                       className="w-full border-collapse bg-white text-left text-sm text-gray-500 rounded-md">
                    <TableHeader>
                        <TableRow>
                            {table.header.map((header, i) => (
                                <TableHead key={i}>{header}</TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {table.rows.map((row, i) => (
                            <TableRow key={i}>
                                {row.map((cell, j) => (
                                    <TableCell key={j}>{cell}</TableCell>
                                ))}
                                <TableCell className={"flex gap-2 text-gray-400"}>
                                    <button className="text-xl"><IoEyeOutline/></button>
                                    <button className="text-xl"><RxPencil2/></button>
                                    <button className="text-xl"><GoTrash/></button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ))}
        </div>
    );
};

export default TemplatesTable;
