import DefaultInput from "../../../../../components/inputs/defaultInput";
import {SelectInput} from "../../../../../components/inputs/selectInput";
import {RadioInput} from "../../../../../components/inputs/radioInput";

const PrintReceiptForm=({})=>{
    const filterBy = [
        {id: "1", name: "Receipt Date", value: "rec"},
        {id: "2", name: "System Entry Date", value: "sys"},
    ]
    const includeCancelled = [
        {id: "1", name: "Yes", value: "yes"},
        {id: "2", name: "No", value: "no"},
    ]
    return(
        <div className={"grid grid-cols-3 gap-4"}>
            <SelectInput options={[]} label={"Select Class"}/>
            <SelectInput options={[]} label={"Select Student"}/>
            <DefaultInput label={"Roll Number"}/>
            <DefaultInput label={"Receipt Number"}/>
            <div className={"col-span-3"}>
                <RadioInput legend={"Filter by"} horizontal data={filterBy}/>
            </div>
            <DefaultInput label={"From Date"}/>
            <DefaultInput label={"To Date"}/>
            <SelectInput options={[]} label={"Select Mode"}/>
            <SelectInput options={[]} label={"Employee"}/>
            <SelectInput options={[]} label={"Type of receipt"}/>
            <div className={"col-span-3"}>
                <RadioInput legend={"Include Cancelled"} horizontal data={includeCancelled}/>
            </div>
            <SelectInput options={[]} label={"Sort by"}/>
        </div>
    )
}
export default PrintReceiptForm
