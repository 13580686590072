import React from 'react';
import Data from "./components/data";
import QuickLinks from "./components/quickLinks";
import InboxCard from "./components/inboxCard";
import TimeTable from "./components/timeTable";
import NoticeCard from "./components/noticeCard";
import AssignmentsTable from "./components/assignmetsTable";

const Dashboard = () => {
    const link = (Data.find(widget => widget.widget === "quickLink") || {}).data;

    return (
        <div className={"grid grid-cols-4 gap-4 p-4"}>
            <div className={"col-span-4 grid grid-cols-8 gap-2"}>
                <div className={"col-span-8"}>
                    <span className={"font-medium"}>Quick Links</span>
                </div>
                {link ? (link.map((item, index) => (
                        <QuickLinks item={item} key={index}/>
                    ))
                ) : (
                    <p>No quick links found.</p>
                )}
            </div>
            <div className={"col-span-3 flex flex-col gap-4"}>
                <div className={"grid grid-cols-3 gap-4"}>
                    <TimeTable/>
                    <NoticeCard/>
                </div>
                <div className={"w-full"}>
                    <AssignmentsTable/>
                </div>
            </div>
            <InboxCard/>
        </div>
    )
}

export default Dashboard;
