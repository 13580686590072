import ConcessionForm from "./components/concessionForm";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";


const RemoveConcessions = () => {
    return (
        <div className={"flex flex-col gap-4"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Fee', url: "/fee"},
                    {label: 'Remove Concession'},
                ]}
            />

            <div className={"flex flex-col gap-4"}>
                <ConcessionForm/>
            </div>
        </div>
    )
}
export default RemoveConcessions;
