import React, { useState } from "react";
import {LuLayoutDashboard} from "react-icons/lu";
import {SiBasicattentiontoken} from "react-icons/si";
import {HiOutlineAcademicCap} from "react-icons/hi";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import StudentTable from "./components/table";
import LinkButton from "../../../../components/buttons/linkButton";
import { IoMdAdd } from "react-icons/io";

const menu = [
    {icon: <LuLayoutDashboard/>, path: "", label: "Personal Detail"},
    {icon: <SiBasicattentiontoken/>, path: "parent", label: "Parents Detail"},
    {icon: <HiOutlineAcademicCap/>, path: "additional", label: "Additional Detail"},
];

const AdmitStudent = () => {
    const [search, setSearch] = useState()
    return (
        <div className={"flex flex-col gap-4"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Academic', url: "/academic"},
                    {label: 'Admit Student'},
                ]}
                isSearch={true}
                onSearchChange={(e)=>setSearch(e.target.value)}
                isFilter={true}
                buttons={
                    <div className={"flex gap-2"}>
                        <LinkButton
                            icon={<IoMdAdd/>}
                            iconClass={"text-md"}
                            name={"Add"}
                            variant={"primary"}
                            onClick={"/academic/admit-student/add"}
                        />
                    </div>
                }
            />

            <div className={"p-4"}>
                <StudentTable search={search}/>
            </div>
        </div>
    )
}
export default AdmitStudent;
