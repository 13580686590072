import TimeTableTabs from "./components/timeTableTabs";

const TimeTable = () => {
    return (
        <div>
            <TimeTableTabs/>
        </div>
    )
}
export default TimeTable
