const PersonalDetail=({})=>{
    return (
        <div className={"flex flex-col gap-2"}>
             <span className={"text-md font-medium"}>
                Persona Detail
            </span>
            <div className={"grid grid-cols-3 gap-2"}>
                <DataCard title={"Student Name:"} />
                <DataCard title={"Class Name:"} />
                <DataCard title={"Father Name:"} />
                <DataCard title={"Mother Name:"} />
                <DataCard title={"Address:"} />
                <DataCard title={"A/C Number:"} />
                <DataCard title={"Mobile Number:"} />
                <DataCard title={"Student Ledger"} />
                <DataCard title={"Tags:"} />
            </div>
        </div>
    )
}

const DataCard = ({title})=>{
    return(
        <div className={"border border-gray-200 px-2 h-8 flex items-center rounded-md gap-1"}>
            <span className={"text-gray-500"}>{title}</span>
            <span className={"text-gray-600"}>afsdf</span>
        </div>
    )
}

export default PersonalDetail
