import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import { useNavigate, useParams } from "react-router-dom";
import { createPreAdmissionEnquiry, getPreAdmissionEnquiryDetail, updatePreAdmissionEnquiry } from "../../../../../services/workspace/preAdmissionEnquiry.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { RadioInput } from "../../../../components/inputs/radioInput";
import { SelectInput } from "../../../../components/inputs/selectInput";
import DefaultDatePicker from "../../../../components/inputs/defaultDatePicker";
import moment from "moment";
import { dateFormat } from "../../../../../utils/helper";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";

const AddPreAdmissionEnquiry= () => {
    const genderData = [
        {id: "g1", name: "Male", value: "male"},
        {id: "g2", name: "Female", value: "female"},
    ]

    const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    const fetchDetail = ()=>{
        setLoading(true)
        getPreAdmissionEnquiryDetail(id).then((response)=>{
            setLoading(false)
            setData(response)
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the pre-admission-enquiry data!")
            console.log(error)
        })
    }

    useEffect(()=>{
        if(id) fetchDetail()
    },[id])

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Name is required'),
        father_name: Yup.string().trim().required('Father Name is required'),
        mother_name: Yup.string().trim().required('Mother Name is required'),
        email: Yup.string().trim().required('Email is required'),
        parent_email: Yup.string().trim().required('Parent Email is required'),
        contact: Yup.string().trim().required('Mobile number is required'),
        parent_contact: Yup.string().trim().required('Parent Mobile number is required'),
        gender: Yup.string().trim().required('Gender is required'),
        dob: Yup.string().trim().required('Date of birth is required')
                    .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid()),
        enquiry_date: Yup.string().trim().required('Enquiry date is required')
                    .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid()),
        previous_school: Yup.string().trim().required('Previous school Name is required'),
        class_enquiring_for: Yup.string().trim().required('Class Enquiring For is required'),
        preferred_stream: Yup.string().trim().required('Preferred Stream is required'),
        qualification: Yup.string().trim().required('Qualification is required'),
        address: Yup.string().trim().required('Address is required'),
        country: Yup.string().trim().required('Country is required'),
        state: Yup.string().trim().required('State is required'),
        city: Yup.string().trim().required('City is required'),
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            name: data?.name || "",
            father_name: data?.father_name || "",
            mother_name: data?.mother_name || "",
            email: data?.email || "",
            parent_email: data?.parent_email || "",
            contact: data?.contact || "",
            parent_contact: data?.parent_contact || "",
            gender: data?.gender || "",
            dob: dateFormat(data?.dob) || "",
            enquiry_date: dateFormat(data?.enquiry_date) || "",
            previous_school: data?.previous_school || "",
            class_enquiring_for: data?.class_enquiring_for || "",
            preferred_stream: data?.preferred_stream || "",
            qualification: data?.qualification || "",
            address: data?.address || "",
            country: data?.country || "",
            state: data?.state || "",
            city: data?.city || "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            setIsSubmitting(true)     
            if(id){
                updatePreAdmissionEnquiry(values, id).then((response)=>{
                    toast.success("Pre admission enquiry updated successfully!")
                    navigate("/academic/pre-admission-enquiry")
                }).catch((error)=>{
                    toast.error("Failed to update pre-admission-enquiry!")
                    console.log(error)    
                })
            } else{
                createPreAdmissionEnquiry(values).then((response)=>{
                    toast.success("Pre admission enquiry created successfully!")
                    navigate("/academic/pre-admission-enquiry")
                }).catch((error)=>{
                    toast.error("Failed to create pre-admission-enquiry!")
                    console.log(error)
                })
            }
        }
    })


    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Academic', url: "/academic"},
                    {label: 'Manage Pre-Admission-Enquiry',url: "/academic/pre-admission-enquiry"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"bg-white p-4 flex flex-col gap-4"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"flex flex-col gap-4 md:gap-8"}>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"Name"} 
                                placeholder={"name"}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.name && errors.name ? errors.name : ''}
                            />
                            <DefaultInput 
                                label={"Email Address"}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && errors.email ? errors.email : ''}
                            />
                            <DefaultInput 
                                label={"Mobile Number"}
                                name="contact"
                                value={values.contact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.contact && errors.contact ? errors.contact : ''}
                            />
                            <RadioInput 
                                legend={"Gender"} 
                                horizontal 
                                data={genderData}
                                name="gender"
                                value={values.gender}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.gender && errors.gender ? errors.gender : ''}
                            />
                            <DefaultDatePicker
                                label={'Date of birth'}
                                name="dob"
                                onChange={(data) => setFieldValue('dob',data)}
                                value={values.dob}
                                placeholder={"12/01/2020"}
                                onBlur={handleBlur}
                                error={touched.dob && errors.dob ? errors.dob : ''}
                            />
                            <DefaultInput 
                                label={"Qualification"}
                                name="qualification"
                                value={values.qualification}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.qualification && errors.qualification ? errors.qualification : ''}
                            />
                            <DefaultInput 
                                label={"Previous School Name"}
                                name="previous_school"
                                value={values.previous_school}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.previous_school && errors.previous_school ? errors.previous_school : ''}
                            />
                            <DefaultInput 
                                label={"Preferred Stream"}
                                name="preferred_stream"
                                value={values.preferred_stream}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.preferred_stream && errors.preferred_stream ? errors.preferred_stream : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"Father Name"}
                                name="father_name"
                                value={values.father_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.father_name && errors.father_name ? errors.father_name : ''}
                            />
                            <DefaultInput 
                                label={"Mother Name"}
                                name="mother_name"
                                value={values.mother_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.mother_name && errors.mother_name ? errors.mother_name : ''}
                            />
                            <DefaultInput 
                                label={"Parent Email Address"}
                                name="parent_email"
                                value={values.parent_email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.parent_email && errors.parent_email ? errors.parent_email : ''}
                            />
                            <DefaultInput 
                                label={"Parent Mobile Number"}
                                name="parent_contact"
                                value={values.parent_contact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.parent_contact && errors.parent_contact ? errors.parent_contact : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"Address"} 
                                placeholder={"address"}
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.address && errors.address ? errors.address : ''}
                            />
                            <DefaultInput 
                                label={"Country"} 
                                placeholder={"country"}
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.country && errors.country ? errors.country : ''}
                            />
                            <DefaultInput 
                                label={"State"} 
                                placeholder={"state"}
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.state && errors.state ? errors.state : ''}
                            />
                            <DefaultInput 
                                label={"City"} 
                                placeholder={"city"}
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.city && errors.city ? errors.city : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <SelectInput label={"Grade"} options={[]} />
                            <SelectInput label={"Status"} options={[]} />
                            <DefaultInput label={"Keyword Search"} />
                            <SelectInput label={"Sort By"} options={[]} />
                            <DefaultInput 
                                label={"Class Enquiring For"}
                                name="class_enquiring_for"
                                value={values.class_enquiring_for}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.class_enquiring_for && errors.class_enquiring_for ? errors.class_enquiring_for : ''}
                            />
                            <DefaultDatePicker 
                                label={"Enquiry Date Between"} 
                                name="enquiry_date"
                                onChange={(data) => setFieldValue('enquiry_date',data)}
                                value={values.enquiry_date}
                                placeholder={"12/01/2020"}
                                onBlur={handleBlur}
                                error={touched.enquiry_date && errors.enquiry_date ? errors.enquiry_date : ''}
                            />
                            <DefaultInput label={"To"} />
                            <DefaultDatePicker label={"Enquiries with Reminder Set Between"} />
                            <DefaultInput label={"To"} />
                            <SelectInput label={"Source Of Enquiry"} options={[]} />
                            <SelectInput label={"Enquiry Mode"} options={[]} />
                            <SelectInput label={"Field"} options={[]} />
                        </div>
                    </div>
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    )
}

export default AddPreAdmissionEnquiry
