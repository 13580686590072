import React from "react";
import {Link} from "react-router-dom";
import {IoMdAdd} from "react-icons/io";
import {HiExternalLink} from "react-icons/hi";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const FeeSetup = ({}) => {
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Fee Setup'},
                ]}
            />
            <div className={"grid grid-cols-2 md:grid-cols-4 gap-4 p-4"}>
                <Card
                    title={"Create Fee Cycle"}
                    detail={"Create fee cycle (frequency) of collecting fees for a session."}
                    link={"/fee/fee-cycle"}
                />
                <Card
                    title={"Create Fee Head"}
                    detail={"Define different categories for fees (e.g., Tuition, Sports)."}
                    link={"/fee/create-fee-head"}
                />
                <Card
                    title={"Assign Fee Head Values"}
                    detail={"Assign specific values to different fee heads."}
                    link={"/fee/assign-fee-head"}
                />
                <Card
                    title={"Collect Fees"}
                    detail={"Process and record fee collection from students."}
                    link={"/fee/collect-fee"}
                />
                <Card
                    title={"Print Receipt"}
                    detail={"Generate and print receipts for collected fees."}
                    link={"/fee/print-receipt"}
                />
                <Card
                    title={"Apply Concessions"}
                    detail={"Grant fee concessions to eligible students."}
                    link={"/fee/apply-concessions"}
                />
                <Card
                    title={"Remove Concessions"}
                    detail={"Revoke previously granted fee concessions."}
                    link={"/fee/remove-concessions"}
                />
                <Card
                    title={"Apply Fine"}
                    detail={"Impose fines for late fee payments or other violations."}
                    link={"/fee/apply-fine"}
                />
                <Card
                    title={"Cancelled Receipts"}
                    detail={"Manage receipts that have been cancelled."}
                    link={"/fee/cancelled-receipts"}
                />
                <Card
                    title={"Fee Waiver"}
                    // detail={"Approve or manage fee waivers for students."}
                    detail={"Coming Soon..."}
                    link={"/fee/fee-weiver"}
                />
            </div>
        </div>
    )
}

const Card = ({title, detail, add, link}) => {
    return (
        <div className={`flex flex-col justify-between bg-white p-3 hover:bg-white/60 
        cursor-pointer rounded-md border`}>
            <div className={"flex flex-col"}>
                <span className={"text-md"}>{title}</span>
                <span className={"text-xs"}>{detail}</span>
            </div>

            {add ?
                <Link to={link} className={"text-xs text-theme-600 mt-3 flex items-center gap-[4px] font-medium"}>
                    <span className={"w-5 h-5 rounded-full flex justify-center items-center bg-theme-600"}>
                        <IoMdAdd className={"text-sm text-white"}/>
                   </span>
                    Add More
                </Link>
                :
                <Link to={link} className={"text-xs text-theme-600 mt-3 flex items-center gap-[4px] font-medium"}>
                    Show All
                    <span className={"rounded-full flex justify-center items-center"}>
                        <HiExternalLink className={"text-md text-theme-600"}/>
                   </span>
                </Link>
            }
        </div>
    )
}
export default FeeSetup;
