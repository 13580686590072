import React, {useState} from "react";
import {IoSearchOutline} from "react-icons/io5";

const GlobalSearch = ({
                          id,
                          type,
                          placeholder,
                          required,
                          name,
                          classnames,
                          icon,
                          shortKey,
                          onSearch,
                      }) => {
    const [searchValue, setSearchValue] = useState("");

    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === "f") {
            event.preventDefault();
            onSearch(searchValue);
        }
    };

    const handleFocus = () => {
        onSearch(searchValue);
    };

    return (
        <div className={"flex flex-col gap-1 text-black relative"}>
            <input
                id={id}
                type={type ? type : "text"}
                placeholder={placeholder || "Search here..."}
                required={!!required}
                name={name}
                className={`ring-0 focus:ring-0 focus:shadow-none focus:border-gray-200
                    h-8 rounded-md border-gray-200 text-xs w-48 ${classnames}
                    ${shortKey ? "pl-10" : "pl-8"}`}
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
            />
            {shortKey ? (
                <span
                    className={`w-8 h-[30px] flex justify-center items-center absolute top-[1px] left-[1px] text-md
                    bg-gray-50 rounded-l-md text-xs text-gray-600`}
                >
                    {shortKey}
                </span>
            ) : (
                <span className={"w-8 h-8 flex justify-center items-center absolute top-0 text-md"}>
                    {icon ? icon : <IoSearchOutline/>}
                </span>
            )}
        </div>
    );
};

export default GlobalSearch;
