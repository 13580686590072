import React from "react";
import PaymentTypeForm from "./components/paymentTypeForm";
import SelectStudentForm from "./components/selectStudentForm";
import PersonalDetail from "./components/personalDetail";
import CollectFeeTable from "./components/table";
import PaymentDetailForm from "./components/paymentDetailForm";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";


const CollectFees = () => {
    return (
        <div className={"flex flex-col gap-4"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Fee', url: "/fee"},
                    {label: 'Collect Fee'},
                ]}
            />
            <div className={"flex flex-col w-full gap-4 bg-white p-4 rounded-md"}>
                <div className={"grid grid-cols-2 gap-4"}>
                    <PaymentTypeForm/>
                    <SelectStudentForm/>
                </div>
                <PersonalDetail/>

                <CollectFeeTable/>

                <PaymentDetailForm/>
            </div>
        </div>
    )
}
export default CollectFees;
