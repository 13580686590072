import React, { useEffect, useState } from "react";
import {
    Table,
    TableHeader,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from "../../../../../components/table/tableComponent";
import { RxPencil2 } from "react-icons/rx";
import { GoTrash } from "react-icons/go";
import DefaultPagination from "../../../../../components/table/pagination";
import TableSkeleton from "../../../../../components/skeleton/tableSkeleton";
import ConfirmModal from "../../../../../components/modals/ConfirmModel";
import { deleteDepartment, getDepartmentList } from "../../../../../../services/workspace/department.service";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const TableHeaderData = [
    "Name", "Abrv", "Description", "Action"
];

const DepartmentTable = ({ search }) => {
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState({ data: [], total: 0 })
    const [limit, setLimit] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [isOpen, setIsOpen] = useState(false)
    const [selectedId, setSelectedId] = useState(false)

    const fetchData = () => {
        setLoading(true)
        getDepartmentList({ search: search, page: (search ? 1 : currentPage), limit: limit }).then((response) => {
            setLoading(false)
            setTableData(response)
            console.log(response)
        }).catch((error) => {
            toast.error("Failed to fetch department data!");
            console.log(error)
        })
    }

    useEffect(() => {
        const newTimer = setTimeout(() => fetchData(), search ? 500 : 0)
        return () => clearTimeout(newTimer)
    }, [search, currentPage])

    const onConfirm = () => {
        deleteDepartment(selectedId).then((response) => {
            setTableData((prev) => ({ ...prev, data: prev.data.filter((item) => item.id != selectedId) }))
            toast.success("Department deleted successfully!")
        }).catch((error) => {
            toast.error("Failed to delete department.")
            console.log(error)
        })
        setIsOpen(false)
    }

    const handleDelete = (id) => {
        setSelectedId(id)
        setIsOpen(true)
    }

    return (
        <div className="overflow-x-auto rounded-md shadow-sm">
            <Table className="w-full border-collapse bg-white text-left text-sm text-gray-500 rounded-md">
                <TableHeader>
                    <TableRow>
                        {TableHeaderData.map((header, i) => (
                            <TableHead key={i}>{header}</TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {loading ? <TableRow><TableCell colSpan={4} className="text-center"><TableSkeleton /></TableCell></TableRow> :
                        <>
                            {!!tableData.data.length ? tableData.data.map((item, i) => (
                                <TableRow key={i}>
                                    <TableCell>{item.name ? item.name : "No Name"}</TableCell>
                                    <TableCell>{item.abrv ? item.abrv : "-"}</TableCell>
                                    <TableCell>{item.description ? item.description : "-"}</TableCell>
                                    <TableCell className={"flex gap-2 text-gray-400 text-xl"}>
                                        <Link to={`/basic-setup/manage-departments/edit/${item.id}`}>
                                            <RxPencil2 />
                                        </Link>
                                        <button className="text-xl" onClick={() => handleDelete(item.id)}>
                                            <GoTrash />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            )) : (
                                <TableRow>
                                    <TableCell colSpan={4} className="text-center">
                                        No departments found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    }
                </TableBody>
            </Table>
            {!!tableData.total && <DefaultPagination
                totalPages={tableData.total}
                currentPage={currentPage}
                limit={limit}
                setCurrentPage={setCurrentPage}
            />}

            <ConfirmModal
                isOpen={isOpen}
                title="Attention!"
                message="Are you sure you want to delete this department?"
                onConfirm={onConfirm}
                onCancel={() => setIsOpen(false)}
            />
        </div>
    );
};

export default DepartmentTable;
