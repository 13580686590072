import DefaultInput from "../../../../../components/inputs/defaultInput";

const StudentAdditionalDetail = ({}) => {
    return (
        <div className={"grid grid-cols-3 gap-4"}>
            <DefaultInput label={"Date of Admission"}/>
        </div>
    )
}
export default StudentAdditionalDetail
