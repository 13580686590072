export const FileManagerTableData = [
    {
        header: ["Name", "Age", "City", "Name", "Age", "City", "Action"],
        rows: [
            [["Sandeep","ThumbURL"], 28, "New York","John Doe", 28, "New York"],
            ["", 28, "New York","John Doe", 28, "New York"],
            ["", 28, "New York","John Doe", 28, "New York"],
            ["", 28, "New York","John Doe", 28, "New York"],
        ],
    },
];
