import React from "react";
import {Button} from "flowbite-react";
import {FiFilter} from "react-icons/fi";
import {IoIosArrowForward} from "react-icons/io";
import {Link} from "react-router-dom";
import SearchInput from "../inputs/searchInput";
import {FilterDropdown} from "../buttons/filterDropdown";

const PageTableHeader = ({isSearch, onSearchChange, isFilter, buttons, breadcrumbs}) => {

    return (
        <div className="flex justify-between items-center h-14 px-4 border-b border-gray-200">
            {breadcrumbs && (
                <h2 className={"text-md font-medium flex items-center"}>
                    {breadcrumbs.map((crumb, index) => (
                        <span key={index} className={"flex items-center"}>
                                {index > 0 &&
                                    <span className="mx-1">
                                        <IoIosArrowForward/>
                                    </span>
                                }
                            {crumb.url ? (
                                <Link to={crumb.url} className={"text-theme-600"}>
                                    {crumb.label}
                                </Link>
                            ) : (
                                <span>{crumb.label}</span>
                            )}
                            </span>
                    ))}
                </h2>
            )}
            <div className={"flex items-center gap-2"}>
                {isSearch &&
                    <SearchInput
                        placeholder={"Search here..."}
                        onChange={onSearchChange}
                    />
                }
                {isFilter &&
                    <FilterDropdown
                        DropdownHeaderContent={''}
                        trigger={
                            <Button
                                className={`w-8 h-8 border border-gray-200 rounded-md flex justify-center items-center`}>
                                <FiFilter/>
                            </Button>
                        }
                        dropdownData={[]}
                    />
                }
                <div>
                    {buttons}
                </div>
            </div>
        </div>
    )
}
export default PageTableHeader;
