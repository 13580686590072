import React, { useState } from "react";
import AssignRollNumberForm from "./components/assignRollNumberForm";
import AssignRollNumberTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const AssignRollNumber = () => {
    const [tableData, setTableData] = useState([])
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Academic', url: "/academic"},
                    {label: 'Assign Roll Number'},
                ]}
            />
            <div className={"flex flex-col w-full gap-4 bg-white p-4 rounded-md"}>
                <AssignRollNumberForm tableData={tableData} setTableData={setTableData}/>
                <AssignRollNumberTable tableData={tableData}/>
            </div>
        </div>
    )
}
export default AssignRollNumber;
