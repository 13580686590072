import {Link} from "react-router-dom";
import {IoMdAdd} from "react-icons/io";
import React from "react";
import PageHeading from "../../../components/pageHeading/pageHeading";
import {HiExternalLink} from "react-icons/hi";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const AcademicSetup = ({}) => {
    return (
        <div className={"flex flex-col gap-6"}>
            <div className={"flex flex-col"}>
                <PageTableHeader
                    breadcrumbs={[
                        {label: 'Academic'},
                    ]}
                />
                <div className={"grid grid-cols-2 md:grid-cols-4 gap-4 p-4"}>
                    <Card
                        title={"Pre Admission Enquiry"}
                        detail={"Process the admission of new students."}
                        link={"/academic/pre-admission-enquiry"}
                    />
                    <Card
                        title={"Admit Student"}
                        detail={"Process the admission of new students."}
                        link={"/academic/admit-student"}
                    />
                    <Card
                        title={"Approve Profile"}
                        detail={"Approve new or updated student profiles."}
                        link={"/academic/approve-profile"}
                    />
                    <Card
                        title={"Subject Wise Attendance"}
                        detail={"Record attendance by subject for each student."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Take Attendance"}
                        detail={"Record daily or class attendance."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Mark Holiday"}
                        detail={"Set holidays in the attendance system."}
                        link={"/academic/manage-holidays"}
                    />
                    <Card
                        title={"Promote Students"}
                        detail={"Promote students to the next grade or class."}
                        link={"/academic/promote-student"}
                    />
                    <Card
                        title={"Assign Roll Number"}
                        detail={"Assign roll numbers to students."}
                        link={"/academic/assign-roll-number"}
                    />
                    <Card
                        title={"Assign Houses"}
                        detail={"Assign students to different houses."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Design Certificate"}
                        detail={"Create and design certificates for students."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Print Certificate"}
                        detail={"Print designed certificates for students."}
                        link={"/academic/"}
                    />
                </div>
            </div>

            <div className={"flex flex-col"}>
                <PageHeading
                    breadcrumbs={[
                        {label: 'Time Table'},
                    ]}
                />
                <div className={"grid grid-cols-2 md:grid-cols-4 gap-4 p-4"}>
                    <Card
                        title={"Time Table"}
                        detail={"Create and manage the school timetable."}
                        link={"/academic/manage-timetables"}
                    />
                    <Card
                        title={"Timetable Teacherwise"}
                        detail={"Create and manage timetables for individual teachers."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Timetable Classwise"}
                        detail={"Create and manage timetables for individual classes."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Print Timetable"}
                        detail={"Print the created timetables."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Full School Timetable"}
                        detail={"Manage and view the complete school timetable."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Manage Substututions"}
                        detail={"Handle and manage teacher substitutions."}
                        link={"/academic/"}
                    />
                </div>
            </div>

            <div className={"flex flex-col"}>
                <PageHeading
                    breadcrumbs={[
                        {label: 'Library'},
                    ]}
                />
                <div className={"grid grid-cols-2 md:grid-cols-4 gap-4 p-4"}>
                    <Card
                        title={"Library"}
                        detail={"Manage the library and its resources."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Books Category"}
                        detail={"Define and manage categories for books."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Manage Booms"}
                        detail={"Add, edit, or delete book entries in the library."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Return/Issue Books || Bulk"}
                        detail={"Handle the bulk return and issuance of books."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Browse Books"}
                        detail={"Search and browse the library's book collection."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Issue Approval Setup"}
                        detail={"Set up the approval process for issuing books."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Track Library Fines"}
                        detail={"Monitor and track fines for overdue books"}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Approve Issued Books"}
                        detail={"Approve the issuance of books to students or staff."}
                        link={"/academic/"}
                    />
                    <Card
                        title={"Books Report"}
                        detail={"Generate reports on library books and transactions."}
                        link={"/academic/"}
                    />
                </div>
            </div>
        </div>
    )
}

const Card = ({title, detail, add, link}) => {
    return (
        <div className={`flex flex-col justify-between bg-gray-50 p-3 hover:bg-white/10 
        cursor-pointer rounded-md border`}>
            <div className={"flex flex-col"}>
                <span className={"text-md"}>{title}</span>
                <span className={"text-xs"}>{detail}</span>
            </div>

            {add ?
                <Link to={link} className={"text-xs text-theme-600 mt-3 flex items-center gap-[4px] font-medium"}>
                    <span className={"w-5 h-5 rounded-full flex justify-center items-center bg-theme-600"}>
                        <IoMdAdd className={"text-sm text-white"}/>
                   </span>
                    Add More
                </Link>
                :
                <Link to={link} className={"text-xs text-theme-600 mt-3 flex items-center gap-[4px] font-medium"}>
                    Show All
                    <span className={"rounded-full flex justify-center items-center"}>
                        <HiExternalLink className={"text-md text-theme-600"}/>
                   </span>
                </Link>
            }

        </div>
    )
}
export default AcademicSetup;
