import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../../components/buttons/defaultButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { SelectInput } from "../../../../../components/inputs/selectInput";
import TableSkeleton from "../../../../../components/skeleton/tableSkeleton";
import { getClassList } from "../../../../../../services/workspace/class.service";
import { assignStudentRollNumber, generateStudentRollNumber } from "../../../../../../services/workspace/student.service";

const AssignRollNumberForm = ({tableData, setTableData}) => {
    const sortByData = [
        {id: "id", label: "Id", value: "id"},
        {id: "name", label: "Name", value: "name"},
    ]

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [classes, setClasses] = useState([])
    const [sections, setSections] = useState([])

    const fetchData = ()=>{
        Promise.all([
            getClassList(),
        ]).then((response)=>{
            setLoading(false)
            setClasses(response[0].data.map((item)=>({value: item.id, label: item.name, sections: item.sections })))
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the classes data!")
            console.log(error)
        })
    }

    useEffect(()=>{
        fetchData()
    },[])

    const validationSchema = Yup.object().shape({
        prefix: Yup.string().trim().required('Prefix is required'),
        suffix: Yup.string().trim().required('Suffix is required'),
        sort_by: Yup.string().trim().required('Sort By is required'),
        start_number: Yup.number().required("Start Number is required.").typeError("Start Number must be selected."),
        length: Yup.number().required("Length is required.").typeError("Length must be selected."),
        class_id: Yup.number().required("Class is required.").typeError("Class must be selected."),
        section_id: Yup.number().required("Section is required.").typeError("Section must be selected."),
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            prefix: "",
            suffix: "",
            start_number: 0,
            length: 6,
            sort_by: "",
            class_id: "",
            section_id: "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            generateStudentRollNumber(values).then((response)=>{
                setTableData(response.data.data)
                console.log(response)
                toast.success("Students roll number generated successfully!")
            }).catch((error)=>{
                toast.error("Failed to generate roll number!")
                console.log(error)
            })
        }
    })

    const handleAssignRollNumber = ()=>{
        if(tableData.length){
            setIsSubmitting(true)   
            assignStudentRollNumber({ data: tableData }).then((response)=>{
                setIsSubmitting(false)   
                toast.success("Students roll number assigned successfully!")
            }).catch((error)=>{
                toast.error("Failed to assign roll number!")
                console.log(error)
            })
        }
    }

    useEffect(()=>{
        const sectionList = classes.find(item=>item.value == values.class_id)?.sections.map((item)=>({value: item.id, label: item.name }))
        setSections(sectionList)
        if(!sectionList?.find(item=>item.value==values.section_id)) setFieldValue("section_id", "")
    },[values.class_id])

    return (
        <div className={"flex flex-col gap-2"}>
            {loading ? <TableSkeleton /> : 
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <div className={"grid grid-cols-3 gap-2"}>
                    <SelectInput
                        label={"Class"} 
                        id={"class_id"}
                        name={"class_id"}
                        value={values.class_id}
                        options={classes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.class_id && errors.class_id ? errors.class_id : ''}
                    />
                    <SelectInput
                        label={"Section"} 
                        id={"section_id"}
                        name={"section_id"}
                        value={values.section_id}
                        options={sections}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.section_id && errors.section_id ? errors.section_id : ''}
                    />
                    <DefaultInput 
                        label={"Prefix"}
                        name="prefix"
                        value={values.prefix}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.prefix && errors.prefix ? errors.prefix : ''}
                    />
                    <DefaultInput 
                        label={"Suffix"}
                        name="suffix"
                        value={values.suffix}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.suffix && errors.suffix ? errors.suffix : ''}
                    />
                    <DefaultInput 
                        label={"Start Number"}
                        name="start_number"
                        value={values.start_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.start_number && errors.start_number ? errors.start_number : ''}
                    />
                    <DefaultInput 
                        label={"Length"}
                        name="length"
                        value={values.length}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.length && errors.length ? errors.length : ''}
                    />
                    <SelectInput 
                        label={"Sort By"} 
                        options={sortByData}
                        name="sort_by"
                        value={values.sort_by}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.sort_by && errors.sort_by ? errors.sort_by : ''}
                    />
                </div>
                <div className="flex gap-4">
                    <DefaultButton variant="primary" name={"Generate Roll Number"} type="submit"/>
                    <DefaultButton variant="warning" name={"Assign Roll Number"} type="button" loading={isSubmitting} onClick={handleAssignRollNumber}/>
                </div>
            </form>}
        </div>
    )
}

export default AssignRollNumberForm
