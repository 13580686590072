import React from 'react';
import Data from "./components/data";
import QuickLinks from "./components/quickLinks";
import ScheduleCard from "./components/scheduleCard";
import TodayPlan from "./components/todayPlan";
import Documents from "./components/documents";
import ClassProgram from "./components/classProgram";

const TeacherDashboard = () => {
    const link = Data.find(widget => widget.widget === "quickLink")?.data;

    console.log(link)
    return (
        <div className={"grid grid-cols-2 md:grid-cols-4 gap-4 p-4"}>
            <div className={"col-span-3 flex flex-col gap-4"}>
                <div className={"grid grid-cols-2 md:grid-cols-4 gap-4"}>
                    <div className={"col-span-2 md:col-span-4"}>
                        <span className={"font-medium"}>Quick Links</span>
                    </div>
                    {link ? (
                        link.map((item, index) => (
                            <QuickLinks item={item} key={index}/>
                        ))
                    ) : (
                        <p>No quick links found.</p>
                    )}
                </div>

                <div className={"grid grid-cols-1 md:grid-cols-3 gap-4"}>
                    <TodayPlan/>
                    <Documents/>
                    <ClassProgram/>
                </div>
            </div>

            <div className={"col-span-3 md:col-span-1 h-full flex flex-col gap-4"}>
                <div className={"col-span-4"}>
                    <span className={"font-medium"}>Schedule</span>
                </div>
                <ScheduleCard/>
            </div>
        </div>
    )
}

export default TeacherDashboard;
