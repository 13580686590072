import DefaultInput from "../../../../../components/inputs/defaultInput";
import {SelectInput} from "../../../../../components/inputs/selectInput";

const studentPersonalDetail = ({}) => {
    return (
        <div className={"grid grid-cols-3 gap-4"}>
            <SelectInput options={[]} label={"Enrolment In Grade Class"}/>
            <DefaultInput label={"Roll Number"}/>
            <DefaultInput label={"Username"}/>
            <DefaultInput label={"First Name"}/>
            <DefaultInput label={"Last Name"}/>
            <DefaultInput label={"Email"}/>
            <DefaultInput label={"Password"}/>
            <DefaultInput label={"Retype Password"}/>
            <DefaultInput label={"Date of Birth"}/>
            <DefaultInput label={"Mobile Number"}/>
            <DefaultInput label={"Nationality"}/>
            <DefaultInput label={"Date of Admission"}/>
            <DefaultInput label={"Contact Number"}/>
            <DefaultInput label={"House Number"}/>
            <DefaultInput label={"Category"}/>
            <DefaultInput label={"Date of Joining"}/>
            <DefaultInput label={"House Designation"}/>
            <DefaultInput label={"Domicile"}/>
            <DefaultInput label={"Place of Birth"}/>
            <DefaultInput label={"Economically Weaker?"}/>
        </div>
    )
}
export default studentPersonalDetail
