import React from 'react';
import { IoWarningOutline } from 'react-icons/io5';

const ConfirmModal = ({ isOpen, title, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
     <div className="bg-white rounded-lg shadow-lg p-6 w-96">
       {/* Header */}
       <div className="">
        <div className="flex flex-col items-center">
          <IoWarningOutline className="text-2xl text-red-600" />
          <h2 className="text-lg font-semibold mb-4">{title}</h2>
        </div>
      </div>

      {/* Body */}
      <div className="text-center">
        <p className="mb-6">{message}</p>
      </div>

      {/* Footer */}
      <div className="flex justify-center gap-2">
        <button
          onClick={onCancel}
          className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
        >
          Cancel
        </button>
        <button
          onClick={onConfirm}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          Confirm
        </button>
      </div>
     </div>
    </div>
  );
};

export default ConfirmModal;
