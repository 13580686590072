import {Label, Radio} from "flowbite-react";

export function RadioInput({legend, data, horizontal, name, value, onChange, onBlur, error}) {
    return (
        <div>
            <fieldset className={`flex ${horizontal ? "flex-row":"flex-col"} gap-2`}>
                {legend && <legend className={"text-sm font-medium dark:text-white text-gray-600"}>{legend}</legend>}
                {data.map((item) => (
                    <div key={item.id} className={`flex items-center gap-2 `}>
                        <Radio
                            id={item.id} 
                            name={name}
                            value={item.value}
                            checked={value == item.value}
                            disabled={item.disabled}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                        <Label htmlFor={item.id} disabled={item.disabled}>
                            {item.name} {item.disabled && "(disabled)"}
                        </Label>
                    </div>
                ))}
            </fieldset>
            <p className="text-sm text-error-600 p-[3px]">{error}</p>
        </div>
    );
}
