import DefaultInput from "../../../../../components/inputs/defaultInput";

const PaymentTypeForm=({})=>{
    return(
        <div className={"flex flex-col gap-2"}>
             <span className={"text-sm font-medium bg-gray-200 h-8 flex items-center px-2 rounded-t-md"}>
                Select Payment Type
            </span>
            <DefaultInput label={"Payment Type"} placeholder={"Payment Type"}/>
            <DefaultInput label={"Receipt No."} placeholder={"Receipt No."}/>
        </div>
    )
}
export default PaymentTypeForm
