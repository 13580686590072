import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getInitialData } from '../../services/auth/auth.service';

export const initialState = {
  workspaceInfo: null,
  status: 'loading',
  error: null,
};

export const initialData = createAsyncThunk(
  'workspace/initialData',
  async ({}, { rejectWithValue }) => {
    try {
        const response = await getInitialData();
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
  }
);

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspaceInfo: (state, action) => {
      state.workspaceInfo = action.payload;
    },
    clearWorkspaceInfo: (state) => {
      state.workspaceInfo = null;
    },
    addSession: (state, action) => {
      state.workspaceInfo = {
        ...state.workspaceInfo,
        default_workspace: {
          ...state.workspaceInfo.default_workspace,
          sessions: [
            ...state.workspaceInfo.default_workspace.sessions,
            action.payload
          ]
        }
      }
    },
    editSession: (state, action) => {
      state.workspaceInfo = {
        ...state.workspaceInfo,
        default_workspace: {
          ...state.workspaceInfo.default_workspace,
          sessions: [
            action.payload,
            ...(state.workspaceInfo.default_workspace.sessions.filter((item)=>item.id!==action.payload.id))
          ]
        }
      }
    },
    switchSession: (state, action) => {
      state.workspaceInfo = {
        ...state.workspaceInfo,
        default_workspace: {
          ...state.workspaceInfo.default_workspace,
          active_session: action.payload
        }
      }
    },
    searchSession: (state, action) => {
      state.workspaceInfo = {
        ...state.workspaceInfo,
        default_workspace: {
          ...state.workspaceInfo.default_workspace,
          sessions: [
            ...action.payload
          ]
        }
      }
    },
    removeSession: (state, action) => {
      state.workspaceInfo = {
        ...state.workspaceInfo,
        default_workspace: {
          ...state.workspaceInfo.default_workspace,
          sessions: [
            ...(state.workspaceInfo.default_workspace.sessions.filter((item)=>item.id!==action.payload))
          ]
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(initialData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workspaceInfo = action.payload;
        state.error = null;
      })
      .addCase(initialData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setWorkspaceInfo, clearWorkspaceInfo, addSession, editSession, switchSession, searchSession, removeSession } = workspaceSlice.actions;

export default workspaceSlice.reducer;