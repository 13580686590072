import DefaultInput from "../../../../../components/inputs/defaultInput";

const StudentParentDetail = ({}) => {
    return (
        <div className={"grid grid-cols-3 gap-4"}>
            <DefaultInput label={"Mobile Number"}/>
            <DefaultInput label={"Category"}/>
        </div>
    )
}
export default StudentParentDetail
