import DefaultInput from "../../../../../components/inputs/defaultInput";
import {SelectInput} from "../../../../../components/inputs/selectInput";

const CancelledReceiptForm = ({}) => {
    return (
        <div className={"flex flex-col gap-2"}>
            <div className={"grid grid-cols-3 gap-2"}>
                <SelectInput label={"Select Class"} options={[]}/>
                <SelectInput label={"Select Student"} options={[]}/>
                <DefaultInput label={"Roll Number"}/>
                <DefaultInput label={"Receipt Number"}/>
                <DefaultInput label={"Receipt Date From"}/>
                <DefaultInput label={"Receipt Date To"}/>
                <SelectInput label={"Select Mode"} options={[]}/>
                <SelectInput label={"Employee"} options={[]}/>
                <SelectInput label={"Reason"} options={[]}/>
                <DefaultInput label={"Remark"}/>
                <SelectInput label={"Sort By"} options={[]}/>
            </div>
        </div>
    )
}

export default CancelledReceiptForm
