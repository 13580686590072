import DefaultTextarea from "../../../../components/inputs/defaultTextarea";
import DefaultButton from "../../../../components/buttons/defaultButton";
import {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { createLeave, getLeaveDetail, updateLeave } from "../../../../../services/workspace/leave.service";
import * as Yup from "yup";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";
import { getStudentList } from "../../../../../services/workspace/student.service";
import { getClassList } from "../../../../../services/workspace/class.service";
import { SelectInput } from "../../../../components/inputs/selectInput";
import { dateFormat } from "../../../../../utils/helper";
import moment from "moment";
import DefaultDatePicker from "../../../../components/inputs/defaultDatePicker";
import { getLeaveTypeList } from "../../../../../services/workspace/leaveType.service";

export function AddStudentLeave() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [classes, setClasses] = useState([])
    const [sections, setSections] = useState([])
    const [students, setStudents] = useState([])
    const [leaveTypes, setLeaveTypes] = useState([])

    const fetchData = ()=>{
        Promise.all([
            getClassList(),
            getLeaveTypeList(),
        ]).then((response)=>{
            if(!id) setLoading(false)
            setClasses(response[0].data.map((item)=>({value: item.id, label: item.name, sections: item.sections})))
            setLeaveTypes(response[1].data.map((item)=>({value: item.id, label: item.name})))
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the students, leave-types or classes data!")
            console.log(error)
        })
    }

    const fetchStudents = (filter={})=>{
        getStudentList(filter).then((response)=>{
            setStudents(response.data.map((item)=>({value: item.user_id, label: `${item.first_name} ${item.last_name}` })))
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the students data!")
            console.log(error)
        })
    }

    const fetchDetail = ()=>{
        setLoading(true)
        getLeaveDetail(id).then((response)=>{
            setLoading(false)
            setData(response)
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the leave-type data!")
            console.log(error)
        })
    }

    useEffect(()=>{
        fetchData()
        if(id) fetchDetail()
    },[id])

    const validationSchema = Yup.object().shape({
        reason: Yup.string().trim().required('Reason is required'),
        class_id: Yup.number().required("Class is required.").typeError("Class must be selected."),
        section_id: Yup.number().required("Section is required.").typeError("Section must be selected."),
        user_id: Yup.number().required("Student is required.").typeError("Student must be selected."),
        leave_type_id: Yup.number().required("Leave Type is required.").typeError("Leave Type must be selected."),
        start_date: Yup.string().required('Start date is required')
            .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid()),
        end_date: Yup.string().required('End date is required')
            .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid())
            .test('isAfterStart', 'End date must be later than Start date', function(value) {
                const { start_date } = this.parent;
                return moment(value, 'DD/MM/YYYY').isAfter(moment(start_date, 'DD/MM/YYYY'));
            }),
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            class_id: data?.class_id || "",
            section_id: data?.section_id || "",
            user_id: data?.user_id || "",
            leave_type_id: data?.leave_type_id || "",
            reason: data?.reason || "",
            start_date: dateFormat(data?.start_date) || '',
            end_date: dateFormat(data?.end_date) || '',
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            setIsSubmitting(true)     
            if(id){
                updateLeave(values, id).then((response)=>{
                    toast.success("Leave updated successfully!")
                    navigate("/basic-setup/manage-leave-setup")
                }).catch((error)=>{
                    toast.error("Failed to update leave-type!")
                    console.log(error)    
                })
            } else{
                createLeave(values).then((response)=>{
                    toast.success("Leave created successfully!")
                    navigate("/basic-setup/manage-leave-setup")
                }).catch((error)=>{
                    toast.error("Failed to create leave-type!")
                    console.log(error)
                })
            }
        }
    })

    useEffect(()=>{
        const sectionList = classes.find(item=>item.value == values.class_id)?.sections.map((item)=>({value: item.id, label: item.name }))
        setSections(sectionList)
        if(!sectionList?.find(item=>item.value==values.section_id)) setFieldValue("section_id", "")
        // get students list
        if(values.class_id && values.section_id){
            setFieldValue("user_id", "")
            fetchStudents({class_id: values.class_id, section_id: values.section_id})
        }
    },[values.class_id, values.section_id])


    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Basic Setup', url: "/basic-setup"},
                    {label: 'Manage Leave Setup',url: "/basic-setup/manage-leave-setup"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"bg-white p-4 flex flex-col gap-4"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"flex flex-col overflow-y-auto gap-4 md:gap-8"}>
                        <div className={"grid grid-cols-1 gap-4"}>
                            <SelectInput
                                label={"Class"} 
                                id={"class_id"}
                                name={"class_id"}
                                value={values.class_id}
                                options={classes}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.class_id && errors.class_id ? errors.class_id : ''}
                            />
                            <SelectInput
                                label={"Section"} 
                                id={"section_id"}
                                name={"section_id"}
                                value={values.section_id}
                                options={sections}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.section_id && errors.section_id ? errors.section_id : ''}
                            />
                            <SelectInput
                                label={"Student"} 
                                id={"user_id"}
                                name={"user_id"}
                                value={values.user_id}
                                options={students}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.user_id && errors.user_id ? errors.user_id : ''}
                            />
                            <SelectInput
                                label={"Leave Type"} 
                                id={"leave_type_id"}
                                name={"leave_type_id"}
                                value={values.leave_type_id}
                                options={leaveTypes}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.leave_type_id && errors.leave_type_id ? errors.leave_type_id : ''}
                            />
                           <DefaultDatePicker
                                error={touched.start_date && errors.start_date ? errors.start_date : ''}
                                name="start_date"
                                onChange={(data) => setFieldValue('start_date',data)}
                                value={values.start_date}
                                placeholder={"12/01/2020"}
                                label={'Start Date'}
                                onBlur={handleBlur}
                            />
                            <DefaultDatePicker
                                error={touched.end_date && errors.end_date ? errors.end_date : ''}
                                name="end_date"
                                onChange={(data) => setFieldValue('end_date',data)}
                                value={values.end_date}
                                placeholder={"12/01/2020"}
                                label={'End Date'}
                                onBlur={handleBlur}
                            />
                            <DefaultTextarea 
                                label={"Reason"} 
                                placeholder={"reason"}
                                name="reason"
                                value={values.reason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.reason && errors.reason ? errors.reason : ''}
                            />
                        </div>
                    </div>
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    );
}
