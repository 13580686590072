const ClassProgram = ({item}) => {
    return (
        <div className={"p-4 bg-white shadow-md rounded-lg h-full"}>
           <span className={"font-medium"}>
               Class Programs
           </span>
        </div>
    )
}
export default ClassProgram;
