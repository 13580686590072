import DefaultInput from "../../../../../components/inputs/defaultInput";
import {SelectInput} from "../../../../../components/inputs/selectInput";

const ConcessionForm=({})=>{
    return (
        <div className={"flex flex-col gap-2"}>
            <div className={"grid grid-cols-3 gap-2"}>
             <DefaultInput label={"Roll Number"}/>
             <SelectInput label={"Concession Type"} options={[]}/>
            </div>
        </div>
    )
}

export default ConcessionForm
