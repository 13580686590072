import DefaultInput from "../../../../../components/inputs/defaultInput";
import {SelectInput} from "../../../../../components/inputs/selectInput";

const PromoteStudentForm = ({}) => {
    return (
        <div className={"flex flex-col gap-2"}>
            <div className={"grid grid-cols-3 gap-2"}>
                <SelectInput label={"Criteria"} options={[]}/>
                <SelectInput label={"Assign Student From"} options={[]}/>
                <SelectInput label={"Assign Student To"} options={[]}/>
                <DefaultInput label={"Roll Number"}/>
                <DefaultInput label={"Length"}/>
            </div>
        </div>
    )
}

export default PromoteStudentForm
