import TableSkeleton from "../../../../../components/skeleton/tableSkeleton";
import NoData from "../../../../../components/noData/noData";

const AssignRollNumberTable = ({ loading=false, tableData }) => {
    return (
        <div className="overflow-x-auto rounded-md shadow-sm">
            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500 rounded-md">
                <thead className="bg-gray-200 rounded-t-md">
                <tr>
                    <th scope="col" className="px-4 py-2 font-medium text-gray-900">
                        Sr No
                    </th>
                    <th scope="col" className="px-4 py-2 font-medium text-gray-900">
                        Name
                    </th>
                    <th scope="col" className="px-4 py-2 font-medium text-gray-900">
                        Roll Number
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                {loading ? (
                    <tr>
                        <td colSpan={8} className="text-center">
                            <TableSkeleton />
                        </td>
                    </tr>
                ) : tableData && tableData.length > 0 ? (
                    tableData.map((item, index) => (
                        <tr className="hover:bg-gray-50" key={index}>
                            <td className="px-4 py-4">{index + 1}</td>
                            <td className="px-4 py-4">
                                <div className="font-medium text-gray-700">
                                    {item.student.first_name} {item.student.last_name}
                                </div>
                            </td>
                            <td className="px-4 py-4 hidden md:table-cell">
                                <div className="font-medium text-gray-700">
                                    {item.roll_no}
                                </div>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={8} className="text-center">
                            <NoData />
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default AssignRollNumberTable;
