import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import { useNavigate, useParams } from "react-router-dom";
import { createDepartment, getDepartmentDetail, updateDepartment } from "../../../../../services/workspace/department.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import DefaultTextarea from "../../../../components/inputs/defaultTextarea";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";

export function AddDepartment({}) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    useEffect(()=>{
        if(id){
            setLoading(true)
            getDepartmentDetail(id).then((response)=>{
                setLoading(false)
                setData(response)
            }).catch((error)=>{
                toast.error("Failed to fetch the department data!")
                console.log(error)
            })
        }
    },[id])

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Name is required'),
        abrv: Yup.string().trim().required('Abbreviation is required'),
        description: Yup.string().trim().required('Description is required')
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            name: data?.name || "",
            abrv: data?.abrv || "",
            description: data?.description || ""
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            setIsSubmitting(true)  
            if(id){
                updateDepartment(values, id).then((response)=>{
                    toast.success("Department updated successfully!")
                    navigate("/basic-setup/manage-departments")
                }).catch((error)=>{
                    toast.error("Failed to update department!")
                    console.log(error)    
                })
            } else{
                createDepartment(values).then((response)=>{
                    toast.success("Department created successfully!")
                    navigate("/basic-setup/manage-departments")
                }).catch((error)=>{
                    toast.error("Failed to create department!")
                    console.log(error)
                })
            }
        }
    })

    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Basic Setup', url: "/basic-setup"},
                    {label: 'Manage Department',url: "/basic-setup/manage-departments"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"flex flex-col w-full gap-4 bg-white p-4 rounded-md"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"grid grid-cols-2 gap-2"}>
                        <DefaultInput 
                            label={"Department Name"}
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && errors.name ? errors.name : ''}
                        />
                        <DefaultInput 
                            label={"Abbreviation"}
                            name="abrv"
                            value={values.abrv}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.abrv && errors.abrv ? errors.abrv : ''}
                        />
                        <DefaultTextarea 
                            label={"Description"}
                            placeholder={"Write Description here..."}
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.description && errors.description ? errors.description : ''}
                        />
                    </div>
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    );
}
