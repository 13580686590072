import React from "react";
import ApproveProfileForm from "./components/approveProfileForm";
import ApproveProfileTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const ApproveProfile = () => {
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Academic', url: "/academic"},
                    {label: 'Approve Profile'},
                ]}
            />
            <div className={"flex flex-col w-full gap-4 bg-white p-4 rounded-md"}>
                <ApproveProfileForm/>
                <ApproveProfileTable/>
            </div>
        </div>
    )
}
export default ApproveProfile;
