import {useEffect, useState} from "react";
import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import { getSectionList } from "../../../../../services/workspace/section.service";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { createClass, getClassDetail, updateClass } from "../../../../../services/workspace/class.service";
import * as Yup from "yup";
import { getStaffList } from "../../../../../services/workspace/staff.service";
import { SelectMultiple } from "../../../../components/inputs/selectMultiple";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";

export function AddClass() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [sections, setSections] = useState([])
    const [staffs, setStaffs] = useState([])

    const fetchData = ()=>{
        Promise.all([
            getSectionList(),
            getStaffList(),
        ]).then((response)=>{
            if(!id) setLoading(false)
            setSections(response[0].data.map((item)=>({value: item.id, label: item.name})))
            setStaffs(response[1].data.map((item)=>({value: item.id, label: item.name})))
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the sections or classes data!")
            console.log(error)
        })
    }

    const fetchDetail = ()=>{
        getClassDetail(id).then((response)=>{
            setLoading(false)
            setData(response)
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the classes data!")
            console.log(error)
        })
    }

    useEffect(()=>{
        fetchData()
        if(id) fetchDetail()
    },[id])

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Name is required'),
        capacity: Yup.number().required('Capacity is required'),
        current_enrollment: Yup.number().required('Current Enrollment is required'),
        room_number: Yup.string().trim().required('Room Number is required'),
        sections: Yup.array().of(Yup.object().required('Section is required')).required('Sections are required').min(1, 'At least one section must be selected'),
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            name: data?.name || "",
            capacity: data?.capacity || "",
            current_enrollment: data?.current_enrollment || "",
            room_number: data?.room_number || "",
            sections: data?.sections?.length ? data?.sections.map((item)=>({value: item.id, label: item.name})) : [],
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            setIsSubmitting(true)     
            values.sections = values.sections.map(item=>item.value)
            if(id){
                updateClass(values, id).then((response)=>{
                    toast.success("Class updated successfully!")
                    navigate("/basic-setup/manage-classes")
                }).catch((error)=>{
                    toast.error("Failed to update class!")
                    console.log(error)    
                })
            } else{
                createClass(values).then((response)=>{
                    toast.success("Class created successfully!")
                    navigate("/basic-setup/manage-classes")
                }).catch((error)=>{
                    toast.error("Failed to create class!")
                    console.log(error)
                })
            }
        }
    })


    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Basic Setup', url: "/basic-setup"},
                    {label: 'Manage classes',url: "/basic-setup/manage-classes"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"bg-white p-4 flex flex-col gap-4"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"flex flex-col gap-4 md:gap-8"}>
                        <div className={"grid grid-cols-1 gap-4"}>
                            <DefaultInput 
                                label={"Name"} 
                                placeholder={"name"}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.name && errors.name ? errors.name : ''}
                            />
                            <DefaultInput 
                                label={"Capacity"} 
                                placeholder={"capacity"}
                                name="capacity"
                                value={values.capacity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.capacity && errors.capacity ? errors.capacity : ''}
                            />
                            <DefaultInput 
                                label={"Current Enrollment"} 
                                placeholder={"current_enrollment"}
                                name="current_enrollment"
                                value={values.current_enrollment}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.current_enrollment && errors.current_enrollment ? errors.current_enrollment : ''}
                            />
                            <DefaultInput 
                                label={"Room Number"} 
                                placeholder={"room_number"}
                                name="room_number"
                                value={values.room_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.room_number && errors.room_number ? errors.room_number : ''}
                            />
                            <SelectMultiple
                                label={"Sections"} 
                                id={"sections"}
                                name={"sections"}
                                value={values.sections}
                                options={sections}
                                onChange={selectedOptions => setFieldValue('sections', selectedOptions)}
                                onBlur={handleBlur}
                                error={touched.sections && errors.sections ? errors.sections : ''}
                            />
                            <DefaultInput label={"Assign Class Teacher"}/>
                        </div>
                    </div>
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    );
}
