import DefaultButton from "../../../components/buttons/defaultButton";
import {MdOutlineWidgets} from "react-icons/md";
import {Link} from "react-router-dom";
import {IoIosArrowForward} from "react-icons/io";

const PageHeading = ({breadcrumbs, subtitle, onClick}) => {
    return (
        <div className={"flex justify-between items-center border-b border-gray-200 h-14 px-4"}>
            <div>
                {breadcrumbs && (
                    <h2 className={"text-md font-medium flex items-center space-x-2"}>
                        {breadcrumbs.map((crumb, index) => (
                            <span key={index} className={"flex items-center"}>
                                {index > 0 &&
                                    <span className="mx-2">
                                        <IoIosArrowForward/>
                                    </span>
                                }
                                {crumb.url ? (
                                    <Link to={crumb.url} className={"text-theme-600"}>
                                        {crumb.label}
                                    </Link>
                                ) : (
                                    <span>{crumb.label}</span>
                                )}
                            </span>
                        ))}
                    </h2>
                )}
                {subtitle && <p className={"text-xs"}>{subtitle}</p>}
            </div>
            {onClick &&
                <DefaultButton
                    variant="iconButton"
                    icon={<MdOutlineWidgets/>}
                />
            }
        </div>
    )
}
export default PageHeading;
