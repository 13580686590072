import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import { useNavigate, useParams } from "react-router-dom";
import { createStudent, getStudentDetail, updateStudent } from "../../../../../services/workspace/student.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { RadioInput } from "../../../../components/inputs/radioInput";
import { SelectInput } from "../../../../components/inputs/selectInput";
import DefaultDatePicker from "../../../../components/inputs/defaultDatePicker";
import moment from "moment";
import { dateFormat } from "../../../../../utils/helper";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";
import { getClassList } from "../../../../../services/workspace/class.service";
import ProfilePic from "../../../../components/inputs/profilePic";
import { UploadInput } from "../../../../components/inputs/uploadInput";

const AddStudent= () => {
    const genderData = [
        {id: "g1", name: "Male", value: "male"},
        {id: "g2", name: "Female", value: "female"},
    ]

    const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [classes, setClasses] = useState([])
    const [sections, setSections] = useState([])

    const fetchData = ()=>{
        Promise.all([
            getClassList(),
        ]).then((response)=>{
            if(!id) setLoading(false)
            setClasses(response[0].data.map((item)=>({value: item.id, label: item.name, sections: item.sections })))
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the classes data!")
            console.log(error)
        })
    }
    const fetchDetail = ()=>{
        getStudentDetail(id).then((response)=>{
            setLoading(false)
            setData(response)
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the student data!")
            console.log(error)
        })
    }

    useEffect(()=>{
        fetchData()
        if(id) fetchDetail()
    },[id])

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().trim().required('First Name is required'),
        last_name: Yup.string().trim().required('Last Name is required'),
        father_name: Yup.string().trim().required('Father Name is required'),
        mother_name: Yup.string().trim().required('Mother Name is required'),
        guardian_name: Yup.string().trim().required('Guardian Name is required'),
        email: Yup.string().trim().required('Email is required'),
        parent_email: Yup.string().trim().required('Parent Email is required'),
        phone: Yup.string().trim().required('Mobile number is required'),
        parent_contact: Yup.string().trim().required('Parent Mobile number is required'),
        guardian_contact: Yup.string().trim().required('Guardian Mobile number is required'),
        gender: Yup.string().trim().required('Gender is required'),
        dob: Yup.string().trim().required('Date of birth is required')
                    .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid()),
        previous_school: Yup.string().trim().required('Previous school Name is required'),
        address: Yup.string().trim().required('Address is required'),
        country: Yup.string().trim().required('Country is required'),
        state: Yup.string().trim().required('State is required'),
        city: Yup.string().trim().required('City is required'),
        class_id: Yup.number().required("Class is required.").typeError("Class must be selected."),
        section_id: Yup.number().required("Section is required.").typeError("Section must be selected."),
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            first_name: data?.first_name || "",
            last_name: data?.last_name || "",
            father_name: data?.father_name || "",
            mother_name: data?.mother_name || "",
            guardian_name: data?.guardian_name || "",
            email: data?.email || "",
            parent_email: data?.parent_email || "",
            phone: data?.phone || "",
            image: data?.profile_image || "",
            parent_contact: data?.parent_contact || "",
            guardian_contact: data?.guardian_contact || "",
            gender: data?.gender || "",
            dob: dateFormat(data?.dob) || "",
            previous_school: data?.previous_school || "",
            address: data?.address || "",
            country: data?.country || "",
            state: data?.state || "",
            city: data?.city || "",
            class_id: data?.class?.class_id || "",
            section_id: data?.class?.section_id || "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            setIsSubmitting(true)   
            if(typeof(values.image)!="object") delete values.image;

            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            if(id){
                formData.append("student_class_id", data?.class?.id)
                updateStudent(formData, id).then((response)=>{
                    toast.success("Student updated successfully!")
                    navigate("/academic/admit-student")
                }).catch((error)=>{
                    toast.error("Failed to update student!")
                    console.log(error)    
                })
            } else{
                createStudent(formData).then((response)=>{
                    toast.success("Student created successfully!")
                    navigate("/academic/admit-student")
                }).catch((error)=>{
                    toast.error("Failed to create student!")
                    console.log(error)
                })
            }
        }
    })

    useEffect(()=>{
        const sectionList = classes.find(item=>item.value == values.class_id)?.sections.map((item)=>({value: item.id, label: item.name }))
        setSections(sectionList)
        if(!sectionList?.find(item=>item.value==values.section_id)) setFieldValue("section_id", "")
    },[values.class_id])

    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Academic', url: "/academic"},
                    {label: 'Manage Admit Student',url: "/academic/admit-student"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"bg-white p-4 flex flex-col gap-4"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"flex flex-col gap-4 md:gap-8"}>
                        <div className="flex flex-col w-32 gap-4 justify-center items-center">
                            <ProfilePic
                                picture={typeof(values.image)=="object"? URL.createObjectURL(values.image) : values.image}
                            />
                            <UploadInput
                                id="image"
                                name="image"
                                label="Choose Profile Pic"
                                onChange={(e)=>setFieldValue("image", e.target.files[0])}
                                onBlur={handleBlur}
                                error={touched.image && errors.image ? errors.image : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"First Name"} 
                                placeholder={"first name"}
                                name="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.first_name && errors.first_name ? errors.first_name : ''}
                            />
                            <DefaultInput 
                                label={"Last Name"} 
                                placeholder={"last name"}
                                name="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.last_name && errors.last_name ? errors.last_name : ''}
                            />
                            <DefaultInput 
                                label={"Email Address"}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && errors.email ? errors.email : ''}
                            />
                            <DefaultInput 
                                label={"Mobile Number"}
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.phone && errors.phone ? errors.phone : ''}
                            />
                            <RadioInput 
                                legend={"Gender"} 
                                horizontal 
                                data={genderData}
                                name="gender"
                                value={values.gender}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.gender && errors.gender ? errors.gender : ''}
                            />
                            <DefaultDatePicker
                                label={'Date of birth'}
                                name="dob"
                                onChange={(data) => setFieldValue('dob',data)}
                                value={values.dob}
                                placeholder={"12/01/2020"}
                                onBlur={handleBlur}
                                error={touched.dob && errors.dob ? errors.dob : ''}
                            />
                            <DefaultInput 
                                label={"Previous School Name"}
                                name="previous_school"
                                value={values.previous_school}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.previous_school && errors.previous_school ? errors.previous_school : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"Father Name"}
                                name="father_name"
                                value={values.father_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.father_name && errors.father_name ? errors.father_name : ''}
                            />
                            <DefaultInput 
                                label={"Mother Name"}
                                name="mother_name"
                                value={values.mother_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.mother_name && errors.mother_name ? errors.mother_name : ''}
                            />
                            <DefaultInput 
                                label={"Parent Email Address"}
                                name="parent_email"
                                value={values.parent_email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.parent_email && errors.parent_email ? errors.parent_email : ''}
                            />
                            <DefaultInput 
                                label={"Parent Mobile Number"}
                                name="parent_contact"
                                value={values.parent_contact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.parent_contact && errors.parent_contact ? errors.parent_contact : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"Guardian Name"}
                                name="guardian_name"
                                value={values.guardian_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.guardian_name && errors.guardian_name ? errors.guardian_name : ''}
                            />
                            <DefaultInput 
                                label={"Guardian Mobile Number"}
                                name="guardian_contact"
                                value={values.guardian_contact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.guardian_contact && errors.guardian_contact ? errors.guardian_contact : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"Address"} 
                                placeholder={"address"}
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.address && errors.address ? errors.address : ''}
                            />
                            <DefaultInput 
                                label={"Country"} 
                                placeholder={"country"}
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.country && errors.country ? errors.country : ''}
                            />
                            <DefaultInput 
                                label={"State"} 
                                placeholder={"state"}
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.state && errors.state ? errors.state : ''}
                            />
                            <DefaultInput 
                                label={"City"} 
                                placeholder={"city"}
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.city && errors.city ? errors.city : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <SelectInput
                                label={"Class"} 
                                id={"class_id"}
                                name={"class_id"}
                                value={values.class_id}
                                options={classes}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.class_id && errors.class_id ? errors.class_id : ''}
                            />
                            <SelectInput
                                label={"Section"} 
                                id={"section_id"}
                                name={"section_id"}
                                value={values.section_id}
                                options={sections}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.section_id && errors.section_id ? errors.section_id : ''}
                            />
                        </div>
                    </div>
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    )
}

export default AddStudent
