import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import React from "react";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

export function AddFeeCycle({}) {
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Fee', url: "/fee"},
                    {label: 'Fee Cycle',url: "/fee/fee-cycle"},
                    {label: 'Add',},
                ]}
            />
            <div className={"flex flex-col w-full gap-4 bg-white p-4"}>
                <div className={"flex flex-col p-4 h-[calc(100vh-122px)] overflow-y-auto"}>
                    <div className={"grid grid-cols-1 gap-4"}>
                        <DefaultInput label={"Cycle Name"}/>
                        <DefaultInput label={"Abbreviation"}/>
                        <DefaultInput label={"From Date"}/>
                        <DefaultInput label={"To Date"}/>
                        <DefaultInput label={"Start Date (Online Payments)"}/>
                        <DefaultInput label={"End Date (Online Payments)"}/>
                    </div>
                </div>
                <div>
                    <DefaultButton variant="primary" name={"Submit"}/>
                </div>
            </div>
        </div>
    );
}
