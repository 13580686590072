import React, {useState} from "react";
import StudentLeaveTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import LinkButton from "../../../../components/buttons/linkButton";
import {IoMdAdd} from "react-icons/io";

const ManageStudentLeave = () => {
    const [search, setSearch] = useState();

    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Basic Setup', url: "/basic-setup"},
                    {label: 'Manage Student Leaves'},
                ]}
                isSearch={true}
                onSearchChange={(e)=>setSearch(e.target.value)}
                isFilter={true}
                buttons={
                    <div className={"flex gap-2"}>
                        <LinkButton
                            icon={<IoMdAdd/>}
                            iconClass={"text-md"}
                            name={"Add"}
                            variant={"primary"}
                            onClick={"/basic-setup/manage-student-leave/add"}
                        />
                    </div>
                }
            />
            <div className={"p-4"}>
                <StudentLeaveTable search={search}/>
            </div>
        </div>
    )
}
export default ManageStudentLeave;
