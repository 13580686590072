import {Fragment} from "react";
import {Drawer} from "flowbite-react";
import DefaultInput from "../../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../../components/buttons/defaultButton";
import {SelectInput} from "../../../../../components/inputs/selectInput";

export function AddAssignFeeHead({isOpen, handleClose}) {

    return (
        <Fragment>
            <Drawer open={isOpen} onClose={handleClose} position="right" className={"p-0 w-300"}>
                <div className={"p-4 border-b border-gray-200"}>
                    <span className={"font-medium text-md"}>Add Assign Fee Head</span>
                </div>
                <div className={"flex flex-col p-4 h-[calc(100vh-122px)] overflow-y-auto"}>
                    <div className={"grid grid-cols-1 gap-4"}>
                        <DefaultInput label={"Student"}/>
                        <DefaultInput label={"Abbreviation"}/>
                        <SelectInput
                            options={[]}
                            label={"Fee Cycle"}
                        />
                        <SelectInput
                            options={[]}
                            label={"Fee Head"}
                        />
                        <SelectInput
                            options={[]}
                            label={"Fee Head Amount"}
                        />
                        <SelectInput
                            options={[]}
                            label={"Apply On"}
                        />
                    </div>
                </div>
                <div className={"p-4 flex justify-end"}>
                    <DefaultButton
                        variant="primary"
                        name={"Submit"}
                    />
                </div>
            </Drawer>
        </Fragment>
    );
}
