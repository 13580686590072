import React, { useState } from "react";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import LinkButton from "../../../../components/buttons/linkButton";
import { IoMdAdd } from "react-icons/io";
import PreAdmissionEnquiryTable from "./components/table";

const ManagePreAdmissionEnquiry = () => {
    const [search, setSearch] = useState();

    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Academic', url: "/academic"},
                    {label: 'Manage Pre Admission Enquiry'},
                ]}
                isSearch={true}
                onSearchChange={(e)=>setSearch(e.target.value)}
                isFilter={true}
                buttons={
                    <div className={"flex gap-2"}>
                        <LinkButton
                            icon={<IoMdAdd/>}
                            iconClass={"text-md"}
                            name={"Add"}
                            variant={"primary"}
                            onClick={"/academic/pre-admission-enquiry/add"}
                        />
                    </div>
                }
            />
            <div className={"p-4"}>
                <PreAdmissionEnquiryTable search={search}/>
            </div>
        </div>
    )
}
export default ManagePreAdmissionEnquiry;
