import {Checkbox, Label} from "flowbite-react";
// import PermissionData from "../../main/basicSetup/innerScreens/manageRoles/components/permissionData";

export function CheckInput({legend, data=[], permissions=[], checkboxHandler }) {
    return (
        <fieldset className="flex max-w-md flex-col gap-2">
            {legend &&
                <div className={"flex items-center gap-1"}>
                    <Checkbox
                        id={""}
                        name="data"
                        value={""} 
                        defaultChecked={false}
                        disabled={""}
                        className={"w-5 h-4 rounded-1 border-gray-200 bg-white"}
                        onChange={()=>checkboxHandler([...data])}
                        checked={data.every((item)=>permissions.includes(item))}
                    />
                    <h2 className="font-medium text-sm">{legend}</h2>
                </div>
            }
            {data.map((item, index) => (
                <div key={index} className="flex items-center gap-2 pl-6">
                    <Checkbox
                        id={item}
                        name="data"
                        value={item}
                        defaultChecked={false}
                        disabled={false}
                        className={"w-5 h-4 rounded-1 border-gray-200 bg-white"}
                        onChange={()=>checkboxHandler([item])}
                        checked={permissions.includes(item)}
                    />
                    <Label htmlFor={item} disabled={false}>
                        {item.split("-").map(it=>it.charAt(0).toUpperCase()+it.slice(1)).join(" ")} 
                        {false && "(disabled)"}
                    </Label>
                </div>
            ))}
        </fieldset>
    );
}

export function PermissionsCheckbox({ permissionList={}, permissions, checkboxHandler }) {
    let allPermissions = Object.values(permissionList).flat();
    return (
        <div className="flex flex-col gap-2">
            <span className={"font-medium text-md"}>
                <Checkbox
                    id={""}
                    name="data"
                    value={""} 
                    defaultChecked={false}
                    disabled={""}
                    className={"w-5 h-4 rounded-1 border-gray-200 bg-white mr-1"}
                    onChange={()=>checkboxHandler([...allPermissions])}
                    checked={allPermissions.every((item)=>permissions.includes(item))}
                />
                Permissions:
            </span>
            <div className="grid grid-cols-4 gap-4">
                {!!Object.keys(permissionList).length && Object.keys(permissionList).map((item, index) => (
                    <CheckInput
                        key={index}
                        legend={item}
                        data={permissionList[item]}
                        permissions={permissions}
                        checkboxHandler={checkboxHandler}
                        className={"w-5 h-4 rounded-1 border-gray-200 bg-white"}
                    />
                ))}
            </div>
        </div>
    );
}
