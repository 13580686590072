import thumb from "../../../assets/images/198-198.png"
const data = [
    {}, {}, {},
]

const InboxCard = ({ item }) => {
    return (
        <div className={"flex flex-col gap-2"}>
            <span className={"font-medium"}>
                Inbox
            </span>
            <div className={`p-2 bg-white rounded-lg border border-gray-200 flex flex-col gap-2 overflow-y-auto
                 h-[calc(100vh-210px)]`}>
                <div className={"flex flex-col gap-2"}>
                    {data.map((item, index) => {
                        return (
                            <div className={"flex gap-2 bg-gray-50 p-2 rounded-md"} key={index}>
                                <img src={thumb} className={"w-10 h-10 rounded-full bg-gray-200"} />
                                <div className={"flex flex-col flex-1"}>
                                    <span className={"font-medium"}>Elizabeth Turner</span>
                                    <span className={"font-medium text-xs"}>Science</span>
                                    <span>Lorem ipsum dolor sit amet, consect adicing elit.</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default InboxCard;
