import TemplatesTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import LinkButton from "../../../../components/buttons/linkButton";
import {IoMdAdd} from "react-icons/io";
import React from "react";

const ManageTemplates = () => {
    return (
        <div className={"flex flex-col gap-2"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Configuration', url: "/configuration"},
                    {label: 'Manage Templates',},
                ]}
                isSearch={true}
                isFilter={true}
                buttons={
                    <div className={"flex gap-2"}>
                        <LinkButton
                            icon={<IoMdAdd/>}
                            iconClass={"text-md"}
                            name={"Add"}
                            variant={"primary"}
                            onClick={"/configuration/manage-templates/add"}
                        />
                    </div>
                }
            />
            <div className={"px-2"}>
                <TemplatesTable/>
            </div>
        </div>
    )
}
export default ManageTemplates;
