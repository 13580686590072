import { Datepicker } from "flowbite-react";
import moment from 'moment';

const DefaultDatePicker = ({ id, label, type, placeholder, required, name, labelClass, classnames, error, onChange, onBlur, value }) => {
    return (
        <div className={"flex flex-col gap-[2px] relative"}>
            {label &&
                <div className="block">
                    <label
                        className={`text-gray-600 font-medium text-sm ${labelClass}`} htmlFor={id}
                    >
                        {label}{required ? <span className={"text-red-600"}>*</span> : ""}
                    </label>
                </div>
            }
            <div className={`flex flex-col ${error ? 'p-[2px] bg-red-100 rounded-md border border-red-200' : ''}`}>
                <Datepicker
                    name={name}
                    value={value}
                    onSelectedDateChanged={(date) => onChange(moment(date).format('DD/MM/YYYY'))}
                    placeholder={placeholder}
                    className={'customInput !font-normal'}
                    onBlur={onBlur}
                />
                <span className="text-sm text-error-600 p-[3px]">{error}</span>
            </div>
        </div>
    )
}
export default DefaultDatePicker;
