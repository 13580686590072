import {useState} from "react";
import {AddFeeHead} from "./components/addFeeHead";
import CreateFeeHeadTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const CreateFeeHead = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);

    return(
        <div className={"flex flex-col gap-4"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Fee', url: "/fee"},
                    {label: 'Fee Head'},
                ]}
            />
            <AddFeeHead isOpen={isOpen} handleClose={handleClose}/>
            <CreateFeeHeadTable/>
        </div>
    )
}
export default CreateFeeHead;
