import {IoIosArrowForward} from "react-icons/io";
import {IoCalendarClearOutline} from "react-icons/io5";

const NoticeCard = ({item}) => {
    return (
        <div className={"flex flex-col gap-2 col-span-2"}>
           <span className={"font-medium"}>
               Notice
           </span>
            <div className={`p-2 bg-white rounded-lg h-full border border-gray-200 flex flex-col gap-4 max-h-[300px]
             overflow-y-auto`}>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
            </div>
        </div>
    )
}

const Card = ({}) => {
    return (
        <div className={"flex justify-between items-center gap-1 bg-gray-50 rounded-md p-2"}>
            <div className={"flex flex-col text-sm"}>
                <span className={"font-medium"}>Contrary to popular belief</span>
                <span
                    className={"text-gray-400"}>Contrary to popular belief, Lorem Ipsum is not simply random text</span>
                <span className={"text-gray-800 flex items-center gap-1 mt-1 text-xs"}>
                    <IoCalendarClearOutline className={"text-theme-600 text-md"}/>
                    10-10-2023
                </span>
            </div>
            <div className={"px-3"}>
                <IoIosArrowForward/>
            </div>
        </div>
    )
}
export default NoticeCard;
