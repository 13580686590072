export function SelectInput({ label, labelClass, id, name, value, onChange, onBlur, error, required, options = [], classnames }) {
    return (
        <div className={"flex flex-col gap-[2px] relative"}>
            {label &&
                <div className="block">
                    <label
                        className={`text-gray-600 font-medium text-sm ${labelClass}`} htmlFor={id}
                    >
                        {label}{required ? <span className={"text-red-600"}>*</span> : ""}
                    </label>
                </div>
            }
            <div className={`flex flex-col ${error ? 'p-[2px] bg-red-100 rounded-md border border-red-200' : ''}`}>
                <select
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    id={id}
                    required={!!required}
                    className={`ring-0 focus:ring-0 focus:shadow-none focus:border-gray-200 text-sm h-8 rounded-md border-gray-200 py-0 ${classnames}`}
                >
                    <option value="">choose option</option>
                    {!!options.length && options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <span className="text-sm text-error-600 p-[3px]">{error}</span>
            </div>
        </div>
    );
}
