import {Label} from "flowbite-react";
import {IoMdAdd} from "react-icons/io";

export function UploadInput({label, labelClass, id, name, onChange, onBlur, classnames, error}) {
    return (
        <div className={`flex flex-col gap-[2px] relative ${classnames}`}>
            {label &&
                <div className="block">
                    <Label className={`text-gray-600 ${labelClass}`} htmlFor={id} value={label}/>
                </div>
            }
            <label htmlFor={id} className={`ring-0 focus:ring-0 focus:shadow-none focus:border-gray-200 text-sm
                 w-full h-8 rounded-md border border-gray-200 flex items-center gap-[2px] px-2 text-gray-600`}>
                <input
                    type="file"
                    id={id}
                    name={name}
                    className={`hidden`}
                    onChange={onChange}
                    onBlur={onBlur}
                    accept="image/*"
                />
                <IoMdAdd className={"text-md"}/> Upload Photo
            </label>
            <p className="text-sm text-error-600 p-[3px]">{error}</p>
        </div>
    );
}
