import DefaultInput from "../../../../../components/inputs/defaultInput";

const SelectStudentForm=({})=>{
    return (
        <div className={"flex flex-col gap-2"}>
             <span className={"text-sm font-medium bg-gray-200 h-8 flex items-center px-2 rounded-t-md"}>
                Select Student
            </span>
            <DefaultInput label={"Receipt Date"} placeholder={"Receipt Date"}/>
            <DefaultInput label={"Roll Number"} placeholder={"Roll Number"}/>
        </div>
    )
}
export default SelectStudentForm
