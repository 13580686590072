import React, {useState} from "react";
import {Link} from "react-router-dom";
import {IoIosStar, IoIosStarOutline, IoMdAdd} from "react-icons/io";
import {HiExternalLink} from "react-icons/hi";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const ConfigurationSetup = ({}) => {
    return (
        <div className={"flex flex-col gap-6"}>
            <div className={"flex flex-col gap-2"}>
                <PageTableHeader
                    breadcrumbs={[
                        {label: 'Configuration'},
                    ]}
                />
                <div className={"grid grid-cols-2 md:grid-cols-4 gap-2 px-2"}>
                    <Card
                        title={"Message Inbox Setup"}
                        detail={"Configure new message inboxes or manage current inbox setups."}
                        link={"/configuration/manage-inbox-setup"}
                    />
                    <Card
                        title={"Receipt Setup"}
                        detail={"Create new receipt setups or manage current receipt configurations."}
                        link={"/configuration/manage-inbox-setup"}
                    />
                    <Card
                        title={"Manage Dashboard Permissions"}
                        detail={"Set or review permissions for accessing dashboard features."}
                        link={"/configuration/manage-inbox-setup"}
                    />
                    <Card
                        title={"Manage Templates"}
                        detail={"Create Templates"}
                        link={"/configuration/manage-Templates"}
                    />
                </div>
            </div>
        </div>
    )
}

const Card = ({title, detail, add, link}) => {
    const [active, setActive] = useState(false);

    const handleClick = () => {
        setActive(!active);
    };
    return (
        <div className={`flex flex-col justify-between bg-white p-3 hover:bg-white/60 
        cursor-pointer rounded-md border`}>
            <div className={"flex flex-col"}>
                <div className={"flex justify-between items-center"}>
                    <span className={"text-md"}>{title}</span>
                    <span onClick={handleClick} className={"cursor-pointer"}>
                    {active ?
                        <IoIosStar/>
                        :
                        <IoIosStarOutline/>
                    }
                    </span>
                </div>
                <span className={"text-xs"}>{detail}</span>
            </div>

            {add ?
                <Link to={link} className={"text-xs text-theme-600 mt-3 flex items-center gap-[4px] font-medium"}>
                    <span className={"w-5 h-5 rounded-full flex justify-center items-center bg-theme-600"}>
                        <IoMdAdd className={"text-sm text-white"}/>
                   </span>
                    Add More
                </Link>
                :
                <Link to={link} className={"text-xs text-theme-600 mt-3 flex items-center gap-[4px] font-medium"}>
                    Show All
                    <span className={"rounded-full flex justify-center items-center"}>
                        <HiExternalLink className={"text-md text-theme-600"}/>
                   </span>
                </Link>
            }
        </div>
    )
}
export default ConfigurationSetup;
