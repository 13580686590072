import React, {Fragment, useEffect, useState} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import MainSidebar from "../main/components/sidebar/mainSidebar";
import {SiBasicattentiontoken} from "react-icons/si";
import Header from "../main/components/header/header";
import {LuLayoutDashboard} from "react-icons/lu";
import {IoSettingsOutline} from "react-icons/io5";
import {GiPayMoney} from "react-icons/gi";
import {GrConfigure, GrGallery} from "react-icons/gr";
import {HiOutlineAcademicCap} from "react-icons/hi";
import {CiViewTimeline} from "react-icons/ci";
import {MdOutlineFindReplace} from "react-icons/md";
import {FiMessageSquare} from "react-icons/fi";
import {CgProfile, CgSpinner} from "react-icons/cg";
import { AiOutlineLoading } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { initialData } from '../../store/slices/workspace.slice';
import {PiGoogleDriveLogo} from "react-icons/pi";
import { Toaster } from 'react-hot-toast';

const menu = [
    {icon: <LuLayoutDashboard/>, path: "/", label: "Dashboard"},
    {icon: <SiBasicattentiontoken/>, path: "/basic-setup", label: "Basic Setup"},
    {icon: <HiOutlineAcademicCap/>, path: "/academic", label: "Academic"},
    {icon: <GiPayMoney/>, path: "/fee", label: "Fee Setup"},
    {icon: <GrConfigure/>, path: "/configuration", label: "Configuration Setup"},
    {icon: <PiGoogleDriveLogo/>, path: "/file-manager", label: "File Manager"},
    {icon: <IoSettingsOutline/>, path: "/settings", label: "Settings"},
];

const teacherMenu = [
    {icon: <LuLayoutDashboard/>, path: "/teacher/dashboard", label: "Dashboard"},
    {icon: <CiViewTimeline/>, path: "/teacher/time-table", label: "Time Table"},
    {icon: <MdOutlineFindReplace/>, path: "/teacher/find-student", label: "Find Student"},
    {icon: <FiMessageSquare/>, path: "/teacher/messages", label: "Messages"},
    {icon: <GrGallery/>, path: "/teacher/gallery", label: "Gallery"},
    {icon: <CgProfile/>, path: "/teacher/profile", label: "Profile"},
];

const MainRoute = () => {
    const [backButton, SetBackButton] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentRoute = menu.find(menu => menu.path === location.pathname);
    const workspaceStatus = useSelector((state) => state.workspace.status);

    const loadInitialData = () => {
        dispatch(initialData({}));
    }

    useEffect(() => {
        const locationArray = location.pathname.split("/");
        if (locationArray.length > 2) {
            SetBackButton(true);
        } else {
            SetBackButton(false);
        }
    }, [location.pathname]);


    useEffect(() => {
        loadInitialData();
    },[]);

    useEffect(() => {
        if(workspaceStatus === 'failed') navigate('/auth/login');
    },[workspaceStatus]);

    if(workspaceStatus === 'loading' || workspaceStatus === 'failed'){
        return (
            <div className="flex flex-col justify-center items-center h-screen">
                <AiOutlineLoading speed={2} className='animate-spin text-5xl bg-gray-200 rounded-full text-theme-600'/>
                <span>Loading...</span>
            </div>
        );
    }

    return (
        <div className="flex w-screen h-screen text-gray-700 text-sm">
            {/*<SpinnerLoader classes={"fixed l-0 t-0 r-0 b-0 z-40 bg-gray-800"}/>*/}
            <Fragment>
                <MainSidebar menu={menu} teacherMenu={teacherMenu}/>
                <div className={"flex flex-col w-full  md:w-[calc(100%-15rem)]"}>
                    <Header
                        backButton={backButton}
                        navigate={navigate}
                        // label={currentLabel ? currentLabel : ''}
                        menu={menu}
                        teacherMenu={teacherMenu}
                    />
                    <div className={"w-full bg-theme-50 h-[calc(100vh-64px)] overflow-y-auto"}>
                        <Outlet/>
                    </div>
                </div>

                <Toaster/>
            </Fragment>
        </div>
    )
}
// @ts-ignore
export default MainRoute;
