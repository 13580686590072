import React from "react";

const TableSkeleton = () => {
  return (
    <div className={"flex justify-center px-4 py-6"}>
      <div className="flex flex-col items-center gap-2">
        <span className={"text-gray-500 font-medium mt-4"}>Loading...</span>
      </div>
    </div>
  );
};
export default TableSkeleton;
