import FeeCycleTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import LinkButton from "../../../../components/buttons/linkButton";
import {IoMdAdd} from "react-icons/io";
import React from "react";

const FeeCycle = () => {
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Fee', url: "/fee"},
                    {label: 'Fee Cycle',},
                ]}
                isSearch={true}
                isFilter={true}
                buttons={
                    <div className={"flex gap-2"}>
                        <LinkButton
                            icon={<IoMdAdd/>}
                            iconClass={"text-md"}
                            name={"Add"}
                            variant={"primary"}
                            onClick={"/fee/fee-cycle/add"}
                        />
                    </div>
                }
            />
            <div className={"p-4"}>
                <FeeCycleTable/>
            </div>
        </div>
    )
}
export default FeeCycle;
