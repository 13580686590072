export const tableData = [
    {
        header: ["Name", "Age", "City", "Name", "Age", "City", "Action"],
        rows: [
            ["John Doe", 28, "New York","John Doe", 28, "New York"],
            ["John Doe", 28, "New York","John Doe", 28, "New York"],
            ["John Doe", 28, "New York","John Doe", 28, "New York"],
            ["John Doe", 28, "New York","John Doe", 28, "New York"],
        ],
    },
];
