import DefaultInput from "../../../../../components/inputs/defaultInput";

const ApproveProfileForm = ({}) => {
    return (
        <div className={"flex flex-col gap-2"}>
            <div className={"grid grid-cols-3 gap-2"}>
                <DefaultInput label={"Class"}/>
                <DefaultInput label={"Status"}/>
                <DefaultInput label={"Roll Number"}/>
            </div>
        </div>
    )
}

export default ApproveProfileForm
