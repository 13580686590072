import {useState} from "react";
import {AddFine} from "./components/addFine";
import FineTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const ApplyFine=()=>{
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);

    return(
        <div className={"flex flex-col gap-4"}> <PageTableHeader
            breadcrumbs={[
                {label: 'Fee', url: "/fee"},
                {label: 'Apply Fine'},
            ]}
        />
            <AddFine isOpen={isOpen} handleClose={handleClose}/>
            <FineTable/>
        </div>
    )
}
export default ApplyFine;
