const TodayPlan = ({item}) => {
    return (
        <div className={"p-4 bg-white shadow-md rounded-lg h-full"}>
           <span className={"font-medium"}>
               Today's Plan
           </span>
        </div>
    )
}
export default TodayPlan;
