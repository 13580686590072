import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createSession, getSessionDetail, updateSession } from "../../../../../services/workspace/session.service";
import { addSession, editSession } from "../../../../../store/slices/workspace.slice";
import DefaultDatePicker from "../../../../components/inputs/defaultDatePicker";
import { dateFormat } from "../../../../../utils/helper";
import toast from "react-hot-toast";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";

export function AddSession({}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    const fetchDetail = ()=>{
        setLoading(true)
        getSessionDetail(id).then((response)=>{
            setLoading(false)
            setData(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the session data!")
            console.log(error)
        })
    }
    
    useEffect(()=>{
        if(id) fetchDetail()
    },[id])

    // Validation Schema
    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Name is required'),
        session_start: Yup.string().required('Session start date is required')
            .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid()),
        session_end: Yup.string().required('Session end date is required')
            .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid())
            .test('isAfterStart', 'Session end date must be later than session start date', function(value) {
                const { session_start } = this.parent;
                return moment(value, 'DD/MM/YYYY').isAfter(moment(session_start, 'DD/MM/YYYY'));
            }),
    });

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            name: data?.name || '',
            session_start: dateFormat(data?.session_start) || '',
            session_end: dateFormat(data?.session_end) || '',
        },
        validationSchema: validationSchema,
        enableReinitialize: true,   
        onSubmit: (values) => {
            setIsSubmitting(true)     
            if(id){
                updateSession(values, id).then((response)=>{
                    toast.success("Session updated successfully!")
                    dispatch(editSession(response))
                    navigate("/basic-setup/manage-sessions")
                }).catch((error)=>{
                    toast.error("Failed to update session!")
                    console.log(error)
                })
            }else{
                createSession(values).then((response)=>{
                    toast.success("Session created successfully!")
                    dispatch(addSession(response))
                    navigate("/basic-setup/manage-sessions")
                }).catch((error)=>{
                    toast.error("Failed to create session!")
                    console.log(error)
                })
            }
        },
    });
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Basic Setup', url: "/basic-setup"},
                    {label: 'Manage Sessions',url: "/basic-setup/manage-sessions"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"flex flex-col w-full bg-white p-4 rounded-md"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"grid grid-cols-2 gap-2"}>
                        <DefaultDatePicker
                            error={touched.session_start && errors.session_start ? errors.session_start : ''}
                            name="session_start"
                            onChange={(data) => setFieldValue('session_start',data)}
                            value={values.session_start}
                            placeholder={"12/01/2020"}
                            label={'Start Date'}
                            onBlur={handleBlur}
                        />
                        <DefaultDatePicker
                            error={touched.session_end && errors.session_end ? errors.session_end : ''}
                            name="session_end"
                            onChange={(data) => setFieldValue('session_end',data)}
                            value={values.session_end}
                            placeholder={"12/01/2020"}
                            label={'End Date'}
                            onBlur={handleBlur}
                        />
                        <DefaultInput 
                            label={"Session Name"}
                            type="text"
                            id="name"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            error={touched.name && errors.name ? errors.name : null}
                        />
                        <DefaultInput label={"Abbreviation"}/>
                    </div>
                    {/* <CheckboxInput data={checkboxData}/> */}
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    );
}
