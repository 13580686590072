import {SlPicture} from "react-icons/sl";

const ProfilePic = ({ picture, height="100px", width="100px" }) => {
    return (
        <div className={`w-20 h-20 rounded-md border border-gray-200 border-dashed flex justify-center items-center
        text-2xl text-gray-400`}>
            {picture ? 
                <div>
                    <img src={picture} height={height} width={width}/>
                </div> : 
                <SlPicture/>
            }
        </div>
    )
}
export default ProfilePic;
