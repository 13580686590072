import {Outlet, useLocation} from "react-router-dom";

const BasicSetupRoute = () => {
    const location = useLocation();
    const routeWidths = [
        {path: 'basic-setup/manage-sessions/add'},
        {path: 'basic-setup/manage-departments/add'},
        {path: 'basic-setup/manage-designations/add'},
    ];
    // const currentWidth = routeWidths.find(route => location.pathname.includes(route.path)) || 'auto';
    const currentWidth = location.pathname.split("/").some(item=>(["edit", "add"]).includes(item)) || 'auto';

    return (
        <div className={"p-2"}>
            <div
                className={`bg-white h-[calc(100vh-80px)] overflow-y-auto border border-gray-200 rounded-md
                ${currentWidth === 'auto' ? '' : 'theme-container'}`}
            >
                <Outlet/>
            </div>
        </div>
    );
}

export default BasicSetupRoute;
