import {Modal} from "flowbite-react";
import {MdOutlineAccessTime} from "react-icons/md";
import {FaRegCalendarAlt} from "react-icons/fa";

const data = [
    {title: "Time Sheet", icon:<MdOutlineAccessTime/>},
    [
        {name: "Time Sheet", detail: "Untitled"},
        {name: "Time Sheet", detail: "Untitled Untitled"},
        {name: "Time Sheet", detail: "Untitled"}
    ],
    {title: "Attendance", icon:<FaRegCalendarAlt/>},
    [
        {name: "Attendance", detail: "Untitled Untitled"},
        {name: "Attendance", detail: "Untitled"},
        {name: "Attendance", detail: "Untitled"}
    ],
    {title: "Fees", icon:<FaRegCalendarAlt/>},
    [
        {name: "Fees", detail: "Untitled Untitled Untitled"},
        {name: "Fees", detail: "Untitled Untitled"},
        {name: "Fees", detail: "Untitled Untitled"}
    ],
    {title: "Students", icon:<FaRegCalendarAlt/>},
    [
        {name: "Students", detail: "Untitled Untitled"},
        {name: "Students", detail: "Untitled Untitled"},
        {name: "Students", detail: "Untitled Untitled"}
    ],
    {title: "Staff", icon:<FaRegCalendarAlt/>},
    [
        {name: "Staff", detail: "Untitled Untitled"},
        {name: "Staff", detail: "Untitled Untitled"},
        {name: "Staff", detail: "Untitled Untitled"}
    ],
    {title: "Assignments", icon:<FaRegCalendarAlt/>},
    [
        {name: "Assignments", detail: "Untitled Untitled"},
        {name: "Assignments", detail: "Untitled Untitled"},
        {name: "Assignments", detail: "Untitled Untitled"}
    ]
];

const GlobalSearchModal = ({openModal, setOpenModal}) => {
    return (
        <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
            <div className={"flex justify-between items-center pl-6 border-b border-gray-200"}>
                <input className={"!w-full flex h-10"} placeholder={"Type a command or search"}/>
                <Modal.Header className={"!border-0"}/>
            </div>
            <Modal.Body className={"py-2 max-h-[300px]"}>
                <div className="flex flex-col">
                    {data.map((item, index) => (
                        Array.isArray(item) ? (
                            <div key={index} className="flex flex-col mb-4 gap-2">
                                {item.map((subItem, subIndex) => (
                                    <div key={subIndex} className="flex gap-1 items-center text-sm">
                                        <span className={"text-gray-600 font-medium"}>{subItem.name} </span>
                                        <span className={"w-2 h-[1px] bg-gray-400"}/>
                                        <span className={"text-gray-400 text-xs"}>{subItem.detail}</span>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div key={index} className="mb-2">
                                <h2 className="text-xs font-medium text-gray-400 flex gap-1 items-center">
                                    {item.icon}
                                    {item.title}
                                </h2>
                            </div>
                        )
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default GlobalSearchModal;
