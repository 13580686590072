const ScheduleCard = ({item}) => {
    const weekDays = [
        {day: "Mon", date: "22"},
        {day: "Tue", date: "23"},
        {day: "Wed", date: "24"},
        {day: "Thu", date: "25"},
        {day: "Fri", date: "26"},
        {day: "Sat", date: "27"},
    ]

    return (
        <div className={"p-4 bg-white shadow-md rounded-lg h-full"}>
            <div className={"grid grid-cols-6 gap-2"}>
                {weekDays.map((item, index) => {
                    return (
                        <DayCard item={item} key={index}/>
                    )
                })}
            </div>
        </div>
    )
}

const DayCard = (({item}) => {
    return (
        <div className={"flex flex-col items-center gap-2 bg-gray-100 py-4 rounded-full text-xs"}>
            <span className={"font-medium"}>{item.day}</span>
            <span>{item.date}</span>
        </div>
    )
})
export default ScheduleCard;
