import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import { useNavigate, useParams } from "react-router-dom";
import { createHoliday, getHolidayDetail, updateHoliday } from "../../../../../services/workspace/holiday.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import DefaultDatePicker from "../../../../components/inputs/defaultDatePicker";
import moment from "moment";
import { dateFormat } from "../../../../../utils/helper";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";

const AddHoliday= () => {
const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    const fetchDetail = ()=>{
        setLoading(true)
        getHolidayDetail(id).then((response)=>{
            setLoading(false)
            setData(response)
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the holiday data!")
            console.log(error)
        })
    }

    useEffect(()=>{
        if(id) fetchDetail()
    },[id])

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Name is required'),
        description: Yup.string().trim().required('Description is required'),
        date: Yup.string().trim().required('Date is required')
                    .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid()),
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            name: data?.name || "",
            description: data?.description || "",
            date: dateFormat(data?.date) || "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            setIsSubmitting(true)     
            if(id){
                updateHoliday(values, id).then((response)=>{
                    toast.success("Holiday updated successfully!")
                    navigate("/academic/manage-holidays")
                }).catch((error)=>{
                    toast.error("Failed to update holiday!")
                    console.log(error)    
                })
            } else{
                createHoliday(values).then((response)=>{
                    toast.success("Holiday created successfully!")
                    navigate("/academic/manage-holidays")
                }).catch((error)=>{
                    toast.error("Failed to create holiday!")
                    console.log(error)
                })
            }
        }
    })


    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Academic', url: "/academic"},
                    {label: 'Manage Holidays',url: "/academic/manage-holidays"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"bg-white p-4 flex flex-col gap-4"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"flex flex-col gap-4 md:gap-8"}>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"Name"} 
                                placeholder={"name"}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.name && errors.name ? errors.name : ''}
                            />
                            <DefaultInput 
                                label={"Description"} 
                                placeholder={"description"}
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.description && errors.description ? errors.description : ''}
                            />
                            <DefaultDatePicker
                                label={'Date'}
                                name="date"
                                onChange={(data) => setFieldValue('date',data)}
                                value={values.date}
                                placeholder={"12/01/2020"}
                                onBlur={handleBlur}
                                error={touched.date && errors.date ? errors.date : ''}
                            />
                        </div>
                    </div>
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    )
}

export default AddHoliday
