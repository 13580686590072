import {IoCalendarClearOutline} from "react-icons/io5";

const TimeTable = ({item}) => {
    return (
        <div className={"flex flex-col gap-2"}>
           <span className={"font-medium"}>
               Time Table
           </span>
            <div className={`p-2 bg-white rounded-lg h-full border border-gray-200 flex flex-col gap-2 max-h-[300px]
             overflow-y-auto`}>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
            </div>
        </div>
    )
}

const Card = ({}) => {
    return (
        <div className={"flex flex-col gap-1 bg-gray-50 rounded-md"}>
            <div className={"flex gap-1 items-center border-b border-gray-200 p-2"}>
                <div
                    className={"w-10 h-10 rounded-full bg-white flex justify-center items-center text-xl"}>
                    <IoCalendarClearOutline/>
                </div>
                <span className={"font-medium"}>Library Books Setting</span>
            </div>
            <span className={"p-2"}>
                School closed for May 20th to May 31th. Enjoy the holiday! We'll be back on June 1st. Have a great holidays!.
            </span>
        </div>
    )
}
export default TimeTable;
