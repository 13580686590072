import React, { useState } from "react";
import { RiMenuUnfold4Fill } from "react-icons/ri";
import DefaultButton from "../../../components/buttons/defaultButton";
import { MenuDrawer } from "../menuDrawer/menuDrawer";
import GlobalSearchModal from "../../../components/modals/globalSearchModal";
import GlobalSearch from "../../../components/inputs/globalSearch";
import { DropdownButton } from "../../../components/buttons/dropdownButton";
import { Button } from "flowbite-react";
import SessionModal from "../../../components/modals/sessionModal";
import { GoChevronDown } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../store/slices/user.slice";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "../../../../utils/helper";

const dropdownData = [
    { name: 'Profile', path: '/profile' },
    { name: 'Settings', path: '/settings' },
    { name: 'Logout', path: '/' },
];

const Header = ({ label, menu, teacherMenu }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(false);
    const [isSessionOpen, setIsSessionOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const workspaceInfo = useSelector((state) => state.workspace.workspaceInfo);
    const { default_workspace } = workspaceInfo;
    const handleClose = () => setIsOpen(false);
    const dispatch = useDispatch();
    const navigateLogout = useNavigate();

    const handleSearch = (value) => {
        setSearchValue(value);
        setIsGlobalSearchOpen(true);
    };

    const handleSession = () => {
        setIsSessionOpen(true);
    };

    const handleLogout = () => {
        dispatch(logout());
        navigateLogout('/auth/login');
    };

    return (
        <div className={"w-full h-16 border-b border-gray-200 flex justify-between items-center px-4"}>
            {label && <span className={"text-md"}>{label}</span>}
            <GlobalSearch
                placeholder={"Type here to find menu"}
                shortKey={"⌘ F"}
                onSearch={handleSearch}
            />
            <div className={"flex items-center gap-2"}>
                {default_workspace.sessions.length > 0 ?
                    <div onClick={handleSession}
                        className="flex items-center hover:bg-gray-200  hover:rounded-md hover:border-white pl-2 pb-1 pt-1 cursor-pointer">
                        <div className="flex flex-col items-end pr-2">
                            <span className="text-black font-medium">{default_workspace?.active_session?.name.toUpperCase()}</span>
                            <span className="text-xs">Period: {dateFormat(default_workspace?.active_session?.session_start, "YYYY")} - {dateFormat(default_workspace?.active_session?.session_end, "YY")}</span>
                        </div>
                        <GoChevronDown className="pr-1 text-lg" />
                    </div>
                    :
                    <DefaultButton name="Create Session" variant="primary" onClick={handleSession} />
                }
                <div className={"w-px h-8 bg-gray-200"} />
                <DropdownButton
                    header={<div className={"flex items-center gap-2"}>
                        <div className={"w-10 h-10 rounded-full bg-theme-600 flexCenter text-white"}>
                            SM
                        </div>
                        <div>
                            <span className="font-medium text-sm">Sandeep Singh</span>
                            <span className={`truncate text-sm font-normal flex items-center gap-1 text-gray-600`}>
                                Teacher
                            </span>
                        </div>
                    </div>}
                    trigger={
                        <Button
                            className={`w-8 h-8 border border-gray-200 rounded-full flex justify-center items-center`}>
                            SM
                        </Button>
                    }
                    items={dropdownData.map(item => ({
                        ...item,
                        to: item.path === '/' ? undefined : item.path,
                        onClick: item.path === '/' ? handleLogout : undefined,
                      }))}
                />
                <div className={"flex md:hidden"}>
                    <DefaultButton
                        icon={<RiMenuUnfold4Fill />}
                        iconClass={"text-md"}
                        variant={"primary"}
                        onClick={() => setIsOpen(true)}
                    />
                    <MenuDrawer
                        menu={menu}
                        teacherMenu={teacherMenu}
                        handleClose={handleClose}
                        isOpen={isOpen}
                    />
                </div>
            </div>

            <GlobalSearchModal
                openModal={isGlobalSearchOpen}
                setOpenModal={setIsGlobalSearchOpen}
                searchValue={searchValue}
            />

            <SessionModal
                openModal={isSessionOpen}
                setOpenModal={setIsSessionOpen}
            />
        </div>
    );
};

export default Header;
