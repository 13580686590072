import { Link } from "react-router-dom";
import { IoIosStar, IoIosStarOutline, IoMdAdd } from "react-icons/io";
import React, { useState } from "react";
import { HiExternalLink, HiOutlineExternalLink } from "react-icons/hi";
import PageHeading from "../../../components/pageHeading/pageHeading";

const BasicSetup = ({ }) => {
    return (
        <div className={"flex flex-col"}>
            <PageHeading
                breadcrumbs={[
                    { label: 'Basic Setup' },
                ]}
            />
            <div className={"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4"}>
                <Card
                    title={"Manage Sessions"}
                    detail={"Create or modify academic year entries (e.g., 2023-24)."}
                    link={"/basic-setup/manage-sessions"}
                />
                <Card
                    title={"Manage Departments"}
                    detail={"Create or update departmental categories like Sports, Academics, etc."}
                    link={"/basic-setup/manage-departments"}
                />
                <Card
                    title={"Manage Designations"}
                    detail={"Define or change staff titles such as Principal, Sports Incharge, etc."}
                    link={"/basic-setup/manage-designations"}
                />
                <Card
                    title={"Manage Roles"}
                    detail={"Establish user roles and set permissions for different functionalities in Synswift."}
                    link={"/basic-setup/manage-roles"}
                />
                <Card
                    title={"Manage Staff"}
                    detail={"Register new staff or view the complete list of existing staff members."}
                    link={"/basic-setup/manage-staff"}
                />
                <Card
                    title={"Manage Sections"}
                    detail={"Create new sections or see the list of current sections."}
                    link={"/basic-setup/manage-sections"}
                />
                <Card
                    title={"Manage Classes"}
                    detail={"Create new classes or see the list of current classes."}
                    link={"/basic-setup/manage-classes"}
                />
                <Card
                    title={"Manage Subjects"}
                    detail={"Introduce new subjects or review existing subjects offered."}
                    link={"/basic-setup/manage-subjects"}
                />
                <Card
                    title={"Message Inbox Setup"}
                    detail={"Configure new message inboxes or manage current inbox setups."}
                    link={"/basic-setup/manage-message-inbox"}
                />
                <Card
                    title={"Leave Setup"}
                    detail={"Configure general leave settings or review all leave entries."}
                    link={"/basic-setup/manage-leave-setup"}
                />
                <Card
                    title={"Student Leave Setup"}
                    detail={"Set up student leave entries or view existing leave records."}
                    link={"/basic-setup/manage-student-leave"}
                />
                <Card
                    title={"Receipt Setup"}
                    detail={"Create new receipt setups or manage current receipt configurations."}
                />
                <Card
                    title={"Manage Tax"}
                    detail={"Define new tax settings or modify existing tax-related entries."}

                />
                <Card
                    title={"Manage Dashboard Permissions"}
                    detail={"Set or review permissions for accessing dashboard features."}
                />
                <Card
                    title={"Manage Notices"}
                    detail={"Create new notices or manage existing announcements and notifications."}
                />
                <Card
                    title={"Manage Templates"}
                    detail={"Create Templates"}
                />
                <Card
                    title={"HR"}
                    detail={"Coming Soon..."}
                />
                <Card
                    title={"Accounting"}
                    detail={"Coming Soon..."}
                />
            </div>
        </div>
    )
}

const Card = ({ title, detail, add, link }) => {
    const [active, setActive] = useState(false);

    const handleClick = () => {
        setActive(!active);
    };
    return (
        <div className={`flex flex-col justify-between bg-gray-50 p-3 hover:bg-white/10 
        cursor-pointer rounded-md border`}>
            <div className={"flex flex-col gap-1"}>
                <div className={"flex justify-between items-center"}>
                    <Link to={link} className={"text-md"}>{title}</Link>
                    <span onClick={handleClick} className={"cursor-pointer w-5 h-5  flex justify-center items-center"}>
                        {active ?
                            <IoIosStar />
                            :
                            <IoIosStarOutline />
                        }
                    </span>
                </div>
                <span className={"text-xs"}>{detail}</span>
            </div>

            {/* {add ?
                <Link to={link} className={"text-xs text-theme-600 mt-3 flex items-center gap-[4px] font-medium"}>
                    <span className={"w-5 h-5 rounded-full flex justify-center items-center bg-theme-600"}>
                        <IoMdAdd className={"text-sm text-white"}/>
                   </span>
                    Add More
                </Link>
                : */}
            {link &&
                <Link to={link} className={"text-xs text-theme-600 mt-3 flex items-center gap-[2px] font-medium"}>
                    Show All
                    <span className={"rounded-full flex justify-center items-center"}>
                        <HiExternalLink className={"text-md text-theme-600"} />
                    </span>
                </Link>
            }

        </div>
    )
}
export default BasicSetup;
