import {Dropdown} from "flowbite-react";
import {CheckboxInput} from "../inputs/checkboxInput";

const data = [
    { id: "1", name: "Students", value: "students" },
    { id: "2", name: "Teachers", value: "teachers" },
    { id: "3", name: "Subjects", value: "subjects" },
    { id: "4", name: "Notices", value: "notices" },
    { id: "5", name: "Exams", value: "exams", disabled: true },
];

export function FilterDropdown({label, trigger, dropdownData}) {
    return (
        <Dropdown
            label={label ? label : ""}
            className={"hover:border border border-gray-100 w-[200px] bg-white pb-4"}
            renderTrigger={() => trigger}
            dismissOnClick={false}
            placement="bottom-end"
        >
            <div className={"p-2 flex flex-col gap-4"}>
                <span className={"text-sm font-medium"}>Select Filter</span>
                <CheckboxInput data={data}/>
            </div>
        </Dropdown>
    );
}

