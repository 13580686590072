import {PiBooksLight} from "react-icons/pi";
import {TbSteam} from "react-icons/tb";
import {FaTimeline} from "react-icons/fa6";
import {LiaNotesMedicalSolid} from "react-icons/lia";

const Data = [
    {
        widget: "quickLink",
        data: [
            { name: "Attendance", icon: <TbSteam/> },
            { name: "Time Table", icon: <FaTimeline/> },
            { name: "Library", icon: <PiBooksLight/> },
            { name: "Notes", icon: <LiaNotesMedicalSolid/> },
        ]
    },
    {
        widget: "smsTemplate",
        data: [
            { name: "John Doe", className: "10th Grade" },
            { name: "Jane Doe", className: "9th Grade" },
            { name: "Jane Doe", className: "9th Grade" },
            { name: "John Doe", className: "10th Grade" },
            { name: "Jane Doe", className: "9th Grade" },
            { name: "Jane Doe", className: "9th Grade" },
            { name: "John Doe", className: "10th Grade" },
        ]
    },
    {
        widget: "topics",
        data: [
            { topicName: "Mathematics", topicDetail: "Study of numbers and their operations." },
            { topicName: "Science", topicDetail: "Study of the natural and physical world." },
            { topicName: "English", topicDetail: "Study of communication skills." }
        ]
    },
    {
        widget: "staffCategories",
        data: [
            "Teacher",
            "Driver",
            "Guardian",
            "Gatekeeper"
        ]
    },
    {
        widget: "reminders",
        data: [
            { reminder: "Next tuition fee period: June 2024" },
            { reminder: "Page' birthdays: 5th June" },
            { reminder: "Teachers' birthdays: 10th July" },
            { reminder: "Job anniversary: 15th August" },
            { reminder: "Page' birthdays: 5th June" },
            { reminder: "Teachers' birthdays: 10th July" },
            { reminder: "Job anniversary: 15th August" },
            { reminder: "Next tuition fee period: June 2024" },
            { reminder: "Page' birthdays: 5th June" },
            { reminder: "Teachers' birthdays: 10th July" },
            { reminder: "Job anniversary: 15th August" }
        ]
    }
];

export default Data;
