import Select from "react-select"

export function SelectMultiple({ label, labelClass, id, name, value, onChange, onBlur, error, required, options = [], classnames }) {
    return (
        <div className={"flex flex-col gap-[2px] relative"}>
            {label &&
                <div className="block">
                    <label
                        className={`text-gray-600 font-medium text-sm ${labelClass}`} htmlFor={id}
                    >
                        {label}{required ? <span className={"text-red-600"}>*</span> : ""}
                    </label>
                </div>
            }
            <div className={`flex flex-col ${error ? 'p-[2px] bg-red-100 rounded-md border border-red-200' : ''}`}>
                <Select
                    value={value}
                    isMulti
                    name={name}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <span className="text-sm text-error-600 p-[3px]">{error}</span>
            </div>
        </div>
    );
}
