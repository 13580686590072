import { useSelector } from 'react-redux';

const usePermission = (permissionName) => {
  const workspaceInfo = useSelector((state) => state.workspace.workspaceInfo);

  if (!workspaceInfo || !workspaceInfo.permissions) {
    return false;
  }

  return workspaceInfo.permissions.includes(permissionName);
};

export default usePermission;