import {useState} from "react";
import {AddAssignFeeHead} from "./components/addAssignFeeHead";
import AssignFeeHeadTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const AssignFeeHead = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);

    return(
        <div className={"flex flex-col gap-4"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Fee', url: "/fee"},
                    {label: 'Assign Fee Head'},
                ]}
            />
            <AddAssignFeeHead isOpen={isOpen} handleClose={handleClose}/>
            <AssignFeeHeadTable/>
        </div>
    )
}
export default AssignFeeHead;
