import * as React from "react";

const Table = React.forwardRef(({className, wrapperClass, ...props}, ref) => (
    <div className={`overflow-x-auto ${wrapperClass || ""}`}>
        <table
            ref={ref}
            className={`w-full caption-top text-sm ${className || ""}`}
            {...props}
        />
    </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef(({className, ...props}, ref) => (
    <thead ref={ref} className={`bg-gray-200 !rounded-t-md ${className || ""}`} {...props} />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef(({className, ...props}, ref) => (
    <tbody
        ref={ref}
        className={`divide-y divide-gray-100 border-t border-gray-100 ${className || ""}`}
        {...props}
    />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef(({className, ...props}, ref) => (
    <tr
        colSpan={100}
        ref={ref}
        className={`bg-muted font-medium ${className || ""}`}
        {...props}
    />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef(({className, ...props}, ref) => (
    <tr
        ref={ref}
        className={` ${className || ""}`}
        {...props}
    />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef(({className, ...props}, ref) => (
    <th
        ref={ref}
        className={`px-4 py-2 font-medium text-gray-900  ${className || ""}`}
        {...props}
    />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef(({className, ...props}, ref) => (
    <td
        ref={ref}
        className={`px-4 py-2 font-medium ${className || ""}`}
        {...props}
    />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef(({className, ...props}, ref) => (
    <caption
        ref={ref}
        className={` ${className || ""}`}
        {...props}
    />
));
TableCaption.displayName = "TableCaption";

export {
    Table,
    TableHeader,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    TableCell,
    TableCaption,
};
