import React, {Fragment, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {WorkspaceDropdown} from "./components/workspaceDropdown";

const MainSidebar = ({menu, teacherMenu}) => {
    const [user, setUser] = useState("Admin");
    return (
        <div className="flex-col flex-shrink-0 w-60 border-r border-gray-200 hidden md:flex">
            <div className={"flex flex-col flex-1 overflow-y-auto"}>
                <div className={"h-16 flex justify-center items-center border-b border-gray-200 "}>
                    <WorkspaceDropdown
                        DropdownHeaderContent={''}
                        trigger={
                            <button className={`w-full h-full bg-transparent text-gray-600 flex items-center px-4`}>
                                SYNSWIFT SCHOOL
                            </button>
                        }
                    />
                </div>

                <div className={"flex flex-col gap-2 p-2"}>
                    {user === "Admin" ?
                        <Fragment>
                            {menu.map((item, index) => (
                                <Menu
                                    key={index}
                                    icon={item.icon}
                                    path={item.path}
                                    label={item.label}
                                />
                            ))
                            }
                        </Fragment>
                        :
                        <Fragment>
                            {teacherMenu.map((item, index) => (
                                <Menu
                                    key={index}
                                    icon={item.icon}
                                    path={item.path}
                                    label={item.label}
                                />
                            ))}
                        </Fragment>}
                </div>
            </div>
        </div>
    );
};

const Menu = ({icon, path, label}) => {
    const location = useLocation();
    const isActive = path === "/" ? location.pathname === path : location.pathname.includes(path);
    const activeClasses = 'bg-gray-100 hover:bg-gray-200 text-theme-600';
    const inactiveClasses = 'text-gray-600 hover:bg-gray-50';
    return (
        <Link
            to={path}
            className={`text-md flex items-center gap-2 h-8 px-2 rounded-md font-medium
            ${isActive ? activeClasses : inactiveClasses}`}
        >
            <span>{icon ? icon : ""}</span>
            <span className={"text-sm"}>{label}</span>
        </Link>
    );
};
export default MainSidebar;
