import React from 'react';
import {Link} from "react-router-dom";

const variantStyles = {
    default: "bg-indigo-600 hover:bg-indigo-700",
    withoutBackground: "bg-white text-theme-600 gap-1 hover:text-theme-700 text-md",
    link: "text-gray-900 underline-offset-4 hover:underline dark:text-stone-50",
    outline: "px-2 bg-white hover:bg-gray-100 border border-gray-200 rounded-md",
    primary: "bg-theme-600 hover:bg-theme-700 rounded-md text-white px-2",
    secondary: "bg-black/90 hover:bg-black rounded-md text-white px-2",
    danger: "bg-red-600 hover:bg-red-700 rounded-md !text-white px-2",
    success: "bg-green-600 hover:bg-green-700 border !border-green-600 rounded-md !text-white px-2",
    iconButton: "w-8 h-8 p-0 justify-center items-center border border-gray-200 rounded-full text-gray-600 hover:bg-gray-50 bg-white text-xl",
    noStyle: "w-full h-auto block bg-transparent hover:bg-transparent border-0 !rounded-0 !p-0"
};

const sizeStyles = {
    default: "h-8",
    sm: "h-8 rounded-md",
    md: "h-10 rounded-md",
    lg: "h-12 rounded-md",
    icon: "h-10 w-10"
};

const LinkButton = ({name, onClick, icon, iconClass, classnames, variant = 'default', size = 'default'}) => {
    const variantClass = variantStyles[variant] || variantStyles.default;
    const sizeClass = sizeStyles[size] || sizeStyles.default;

    return (
        <Link
            to={onClick}
            className={`flex items-center disabled:pointer-events-none gap-1 disabled:opacity-50 dark:ring-offset-stone-950 dark:focus-visible:ring-stone-300 text-gray-600
             ${variantClass} ${sizeClass} ${classnames}`}
        >
            {icon && <span className={iconClass}>{icon}</span>}
            {name}
        </Link>
    );
}

export default LinkButton;
