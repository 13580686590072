import React from "react";
import PromoteStudentForm from "./components/promoteStudentForm";
import PromoteStudentTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";


const PromoteStudent = () => {
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Academic', url: "/academic"},
                    {label: 'Promote Student'},
                ]}
            />
            <div className={"flex flex-col w-full gap-4 bg-white p-4 rounded-md"}>
                <PromoteStudentForm/>
                <PromoteStudentTable/>
            </div>
        </div>
    )
}
export default PromoteStudent;
