import { Dropdown } from "flowbite-react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export function DropdownButton({ label, trigger, items, header }) {
    return (
        <Dropdown
            label={label ? label : ""}
            className={"hover:border border border-gray-100 w-[200px] bg-white"}
            renderTrigger={() => trigger}
            dismissOnClick={false}
            placement="bottom-end"
        >
            {header &&
                <Dropdown.Header className={"mb-2"}>
                    {header}
                </Dropdown.Header>
            }
            {items.map((item, index) => {
                const { to, onClick, name, path } = item;
                return (
                    <Fragment key={index}>
                        {to ? (
                            <Link
                                to={to}
                                className={`rounded-0 w-full h-7 flex items-center px-4 font-normal text-sm hover:bg-gray-50`}
                            >
                                {name}
                            </Link>
                        ) : (
                            <button
                                onClick={onClick}
                                className={`rounded-0 w-full h-7 flex items-center px-4 font-normal text-sm hover:bg-gray-50`}
                            >
                                {name}
                            </button>
                        )}
                    </Fragment>
                );
            })}
        </Dropdown>
    );
}
