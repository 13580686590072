import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import { useNavigate, useParams } from "react-router-dom";
import { createDesignation, getDesignationDetail, updateDesignation } from "../../../../../services/workspace/designation.service";
import toast from "react-hot-toast";
import * as Yup from "yup"
import { useFormik } from "formik";
import { SelectInput } from "../../../../components/inputs/selectInput";
import { getDepartmentList } from "../../../../../services/workspace/department.service";
import DefaultTextarea from "../../../../components/inputs/defaultTextarea";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";

export function AddDesignation({ }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const[loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [departments, setDepartments] = useState([])

    const fetchData = ()=>{
        getDepartmentList().then((response) => {
            if(!id) setLoading(false)
            const optionlist = response.data.length ? response.data.map((item) => ({ value: item.id, label: item.name })) : [];
            setDepartments(optionlist)
        }).catch((error) => {
            toast.error("Failed to fetch the designation data!")
            console.log(error)
        })
    }

    const fetchDetail = ()=>{
        getDesignationDetail(id).then((response) => {
            setLoading(false)
            setData(response)
        }).catch((error) => {
            toast.error("Failed to fetch the designation data!")
            console.log(error)
        })
    }
    useEffect(() => {
        fetchData()
        if(id) fetchDetail()
    }, [id])

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required("Name is required."),
        abrv: Yup.string().trim().required("Abbreviation is required."),
        description: Yup.string().trim().required("Description is required."),
        department_id: Yup.number().required("Department is required.").typeError("Department must be selected."),
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors } = useFormik({
        initialValues: {
            name: data?.name || "",
            abrv: data?.abrv || "",
            description: data?.description || "",
            department_id: data?.department?.id || "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (id) {
                updateDesignation(values, id).then((response) => {
                    console.log(response)
                    toast.success("Designation updated successfully!")
                    navigate("/basic-setup/manage-designations")
                }).catch((error) => {
                    toast.error("Failed to update designation!")
                    console.log(error)
                })
            } else {
                createDesignation(values).then((response) => {
                    toast.success("Designation created successfully!")
                    navigate("/basic-setup/manage-designations")
                }).catch((error) => {
                    toast.error("Failed to create designation!")
                    console.log(error)
                })
            }
        }
    })

    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    { label: 'Basic Setup', url: "/basic-setup" },
                    { label: 'Manage Designation', url: "/basic-setup/manage-designations" },
                    { label: id ? 'Edit' : 'Add', },
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"flex flex-col w-full bg-white p-4 rounded-md"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"grid grid-cols-3 gap-2"}>
                        <DefaultInput
                            label={"Designation Name"}
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.name && errors.name ? errors.name : ''}
                        />
                        <DefaultInput
                            label={"Abbreviation"}
                            name="abrv"
                            value={values.abrv}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.abrv && errors.abrv ? errors.abrv : ''}
                        />
                        <SelectInput
                            label={"Department"}
                            name="department_id"
                            value={values.department_id}
                            options={departments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.department_id && errors.department_id ? errors.department_id : ''}
                        />
                        <div className="col-span-3">
                            <DefaultTextarea
                                label={"Description"}
                                placeholder={"Write Description here..."}
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.description && errors.description ? errors.description : ''}
                            />
                        </div>
                    </div>
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" />
                    </div>
                </form>
            </div>}
        </div>
    );
}
