import {Fragment} from "react";
import {Drawer} from "flowbite-react";
import DefaultInput from "../../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../../components/buttons/defaultButton";
import {RadioInput} from "../../../../../components/inputs/radioInput";
import {SelectInput} from "../../../../../components/inputs/selectInput";

export function AddConcession({isOpen, handleClose}) {

    const auto = [
        {id: "1", name: "Yes", value: "yes"},
        {id: "2", name: "No", value: "no"},
    ]

    return (
        <Fragment>
            <Drawer open={isOpen} onClose={handleClose} position="right" className={"p-0 w-300"}>
                <div className={"p-4 border-b border-gray-200"}>
                    <span className={"font-medium text-md"}>Add Concession</span>
                </div>
                <div className={"flex flex-col p-4 h-[calc(100vh-122px)] overflow-y-auto"}>
                    <div className={"grid grid-cols-1 gap-4"}>
                        <RadioInput horizontal data={auto} legend={"Auto(For online payment only)"}/>
                        <DefaultInput label={"Name"}/>
                        <SelectInput label={"Value Type"} options={[]}/>
                        <DefaultInput label={"Value"}/>
                        <SelectInput label={"Type"} options={[]}/>
                        <SelectInput label={"Head"} options={[]}/>
                    </div>
                </div>
                <div className={"p-4 flex justify-end"}>
                    <DefaultButton
                        variant="primary"
                        name={"Submit"}
                    />
                </div>
            </Drawer>
        </Fragment>
    );
}
