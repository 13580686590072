import React from 'react';

const DefaultTextarea = ({
                             id,
                             label,
                             placeholder,
                             required,
                             name,
                             labelClass,
                             classnames,
                             error,
                             value,
                             onChange,
                             onBlur
                         }) => {
    return (
        <div className="flex flex-col gap-[2px] relative">
            {label &&
                <div className="block">
                    <label
                        className={`text-gray-600 font-medium text-sm ${labelClass}`} htmlFor={id}
                    >
                        {label}{required ? <span className={"text-red-600"}>*</span> : ""}
                    </label>
                </div>
            }
            <div className={`flex flex-col ${error ? 'p-[2px] bg-red-100 rounded-md border border-red-200':''}`}>
            <textarea
                id={id}
                placeholder={placeholder}
                required={!!required}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                className={`ring-0 focus:ring-0 focus:shadow-none focus:border-gray-200 text-sm h-20
                rounded-md border-gray-200 
                ${classnames}`}
            >   
            </textarea>
            <span className="text-sm text-error-600 p-[3px]">{error}</span>
        </div>
        </div>
    );
};

export default DefaultTextarea;
