import {Checkbox, Label} from "flowbite-react";

export function CheckboxInput({legend, data}) {
    return (
        <fieldset className="flex max-w-md flex-col gap-2">
            {legend && <legend className="mb-4">{legend}</legend>}
            {!!data.length && data.map((item, index) => (
                <div key={index} className="flex items-center gap-2">
                    <Checkbox
                        id={item.id}
                        name="data"
                        value={item.value}
                        defaultChecked={item.value === "USA"}
                        disabled={item.disabled}
                        className={"w-5 h-4 rounded-1 border-gray-200 bg-white"}
                    />
                    <Label htmlFor={item.id} disabled={item.disabled}>
                        {item.name} {item.disabled && "(disabled)"}
                    </Label>
                </div>
            ))}
        </fieldset>
    );
}
