import {Outlet, useLocation} from "react-router-dom";

const AcademicRoute = () => {
    const location = useLocation();
    const routeWidths = [
        {path: '/academic/pre-admission-enquiry'},
        {path: 'basic-setup/manage-departments/add'},
        {path: 'basic-setup/manage-designations/add'},
    ];
    // const currentWidth = routeWidths.find(route => location.pathname.includes(route.path)) || 'auto';
    const currentWidth = location.pathname.split("/").some(item=>(["edit", "add"]).includes(item)) || 'auto';

    return (
        <div className={"p-2"}>
            <div
                className={`bg-white border border-gray-200 rounded-md
                ${currentWidth === 'auto' ? '' : 'theme-container'}`}
            >
                <Outlet/>
            </div>
        </div>
    )
}
export default AcademicRoute;
