import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import DefaultInput from "../../components/inputs/defaultInput";
import DefaultButton from "../../components/buttons/defaultButton";
import { useNavigate } from 'react-router-dom';
import LOGO from '../../assets/images/logo-light.svg'
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../store/slices/user.slice';

const Login = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userStatus = useSelector((state) => state.user.status);
    const userError = useSelector((state) => state.user.error);

    const formik = useFormik({
        initialValues: {
          username: '',
          password: '',
        },
        validationSchema: Yup.object({
          username: Yup.string()
            .required('Username is required'),
          password: Yup.string()
            .required('Password is required'),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values));
        },
    });

    useEffect(() => {
        if(userStatus === 'succeeded') navigate('/');
    },[userStatus]);

    return (
        <div className={"w-full p-5 flex flex-col gap-2 max-w-[400px] bg-white rounded-lg"}>
            <div style={{margin:'20px 0'}} className={"flex flex-col items-center pb-4"}>
                <img src={LOGO} width={300} alt={""}/>
                <span style={{width:'300px'}} className={"text-sm text-center"}>
                    Please use your username to login into workspace dashboard
                </span>
                {userStatus === 'failed' && <p>Error: {userError}</p>}
            </div>
            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
                <DefaultInput
                    error={formik.touched.username && formik.errors.username ? formik.errors.username:''}
                    name="username"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    placeholder={"Your username..."}
                    label={'Username'}
                />
                <DefaultInput
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder={"Your password..."}
                    label={'Password'}
                    error={formik.touched.password && formik.errors.password ? formik.errors.password:''}
                />

                <DefaultButton
                    type={'submit'}
                    disabled={userStatus === 'loading'}
                    loading={userStatus === 'loading'}
                    name={userStatus !== 'loading' ? "Login":""}
                    variant={"primary"}
                    classnames={"flex justify-center"}
                />
            </form>
        </div>
    );
}

export default Login;
