import DefaultInput from "../../../../../components/inputs/defaultInput";

const PaymentDetailForm = ({}) => {
    return (
        <div className={"grid grid-cols-4 gap-2"}>
            <DefaultInput label={"Total Fee"} placeholder={"Payment Type"}/>
            <DefaultInput label={"Total Concessions"} placeholder={"Receipt No."}/>
            <DefaultInput label={"Net Amount"} placeholder={"Receipt No."}/>
            <DefaultInput label={"Previus Overpay"} placeholder={"Receipt No."}/>
            <DefaultInput label={"Previus Dues"} placeholder={"Receipt No."}/>
            <DefaultInput label={"Amount Payable"} placeholder={"Receipt No."}/>
            <DefaultInput label={"Amount Paid"} placeholder={"Receipt No."}/>
            <DefaultInput label={"Payment Mode"} placeholder={"Receipt No."}/>
            <DefaultInput label={"Txn Details"} placeholder={"Receipt No."}/>
            <DefaultInput label={"Bank details"} placeholder={"Receipt No."}/>
            <DefaultInput label={"tabs Receipt"} placeholder={"Receipt No."}/>
        </div>
    )
}
export default PaymentDetailForm
