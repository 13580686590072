import {IoSearchOutline} from "react-icons/io5";

const SearchInput = ({id, type, placeholder, required, name, onChange, classnames, icon,shortKey}) => {
    return (
        <div className={"flex flex-col gap-1 text-black relative"}>
            <input
                id={id} type={type ? type : "text"} placeholder={placeholder || "Search here..."}
                required={!!required} name={name} onChange={onChange}
                className={`ring-0 focus:ring-0 focus:shadow-none focus:border-gray-200
                 h-8 rounded-md border-gray-200 w-48 pl-8 text-xs ${classnames}`}
            />
            <span className={"w-8 h-8 flex justify-center items-center absolute top-0 text-md"}>
                {icon ? icon : <IoSearchOutline/>}
            </span>
        </div>
    )
}
export default SearchInput;
