import React from "react";
import CancelledReceiptForm from "./components/cancelledReceiptForm";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const CancelledReceipts = () => {
    return (
        <div className={"flex flex-col gap-4 theme-container"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Fee', url: "/fee"},
                    {label: 'Cancel Receipt'},
                ]}
            />
            <div className={"flex flex-col w-full gap-4 bg-white p-4 rounded-md"}>
                <CancelledReceiptForm/>
            </div>
        </div>
    )
}
export default CancelledReceipts;
