import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePermission from "../../../hooks/usePermission";
import { Button, Modal } from "flowbite-react";
import DefaultButton from "../buttons/defaultButton";
import SessionForm from "../forms/sessionForm";
import Lottie from "lottie-react";
import NoData from "../../assets/lottie/no-data.json";
import { DropdownButton } from "../buttons/dropdownButton";
import { CiMenuKebab } from "react-icons/ci";
import { deleteSession, updateSession } from "../../../services/workspace/session.service";
import { removeSession, switchSession } from "../../../store/slices/workspace.slice";
import toast from "react-hot-toast";
import { dateFormat } from "../../../utils/helper";

const SessionModal = ({ openModal, setOpenModal, showFormTrig = false }) => {
    const dispatch = useDispatch()
    const [showForm, setShowForm] = useState(showFormTrig);
    const [sessionData, setSessionData] = useState();
    const hasSessionCreatePermission = usePermission('session-create');
    const workspaceInfo = useSelector((state) => state.workspace.workspaceInfo);
    const { default_workspace } = workspaceInfo;

    const handelSwitch = (id)=>{
        updateSession({status: 1}, id).then((response)=>{
            dispatch(switchSession(response))
            setOpenModal(false)
            toast.success("Session switched successfully!")
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to switch session!")
            console.log(error)
        })
    }

    const handelEdit = (id)=>{
        setShowForm(true)
        setSessionData(workspaceInfo.default_workspace.sessions.find((item)=>item.id==id))
    }

    const handelDelete = (id)=>{
        if(window.confirm("Are you sure you want to delete this session?")){
            deleteSession(id).then((response)=>{
                dispatch(removeSession(id))
            }).catch((error)=>{
                console.log(error)
            })
        }
    }

    return (
        <Modal dismissible size={'sm'} show={openModal} onClose={() => setOpenModal(false)}>
            <div className={"flex justify-between items-center pl-4 border-b border-gray-200"}>
                {showForm ? 
                    
                    <h2>{sessionData?.id ?  "Update" : "Create"} Session</h2>
                    :
                    <h2>Session Manager</h2>
                }
                <Modal.Header className={"!border-0 !p-2"} />
            </div>

            <Modal.Body className={`py-2 px-0 ${showForm && 'overflow-visible'}`}>
                {!showForm && <div className="flex flex-col px-2">
                    {default_workspace.sessions.map((session, sIndex) =>
                        <div className={`flex flex-row ${(sIndex + 1) === default_workspace.sessions.length ? '' : 'border-b'}
                         border-gray-200 p-2 justify-between items-center`} key={sIndex}>
                            <div className={`flex flex-col`}>
                                <span className="text-black">{session.name}</span>
                                <span className="text-sm text-black/70">{dateFormat(session.session_start)} - {dateFormat(session.session_end)}</span>
                            </div>
                            <div className="">
                                <DropdownButton
                                    trigger={<Button
                                        className={`w-8 h-8 border border-gray-300 text-black rounded-md flex justify-center items-center bg-white hover:!bg-gray-100`}>
                                        <CiMenuKebab />
                                    </Button>}
                                    items={[
                                        {
                                            name: 'Switch',
                                            onClick: () => handelSwitch(session.id)
                                        },
                                        {
                                            name: 'Edit',
                                            onClick: () => handelEdit(session.id)
                                        },
                                        {
                                            name: 'Delete',
                                            onClick: () => handelDelete(session.id)
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                    )}


                    {default_workspace.sessions.length <= 0 && <div className={"flex flex-col items-center relative mb-2"}>
                        <Lottie animationData={NoData} loop={false} className="h-[200px]" />
                        <span className={"bottom-5 font-bold text-gray-600"}>
                            No Sessions
                        </span>
                        <span className={"bottom-5 text-sm text-center  text-gray-500"}>
                            Start Creating the first session using the below button
                        </span>
                    </div>}
                </div>
                }

                {showForm ?
                    <SessionForm setShowForm={setShowForm} sessionData={sessionData} setSessionData={setSessionData}/>
                    :
                    null
                }
            </Modal.Body>
            {!showForm && <Modal.Footer className="py-2 px-4">
                <div className="flex flex-row gap-3 w-full">
                    <DefaultButton
                        onClick={() => {
                            setSessionData()
                            setOpenModal(false)
                        }}
                        name="Close"
                        variant="outline"
                    />
                    {hasSessionCreatePermission &&
                        <DefaultButton
                            onClick={() => setShowForm(true)}
                            name="Create Session"
                            variant="primary"
                        />
                    }
                </div>
            </Modal.Footer>}
        </Modal>
    );
}

export default SessionModal;
