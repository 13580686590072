import React from "react";
import {IoMdAdd} from "react-icons/io";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import LinkButton from "../../../../components/buttons/linkButton";
import {FiUpload} from "react-icons/fi";
import {IoFolderOpen} from "react-icons/io5";
import FilesTable from "./components/table";

const FileManager = ({}) => {
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'File Manager'},
                ]}
                isSearch={true}
                buttons={
                    <div className={"flex gap-2"}>
                        <LinkButton
                            icon={<FiUpload/>}
                            iconClass={"text-md"}
                            name={"Upload"}
                            variant={"outline"}
                            onClick={""}
                        />
                        <LinkButton
                            icon={<IoMdAdd/>}
                            iconClass={"text-md"}
                            name={"Create"}
                            variant={"primary"}
                            onClick={""}
                        />
                    </div>
                }
            />
            <div className={"flex flex-col gap-4 px-4"}>
                <div>
                    <div className={"h-10 flex items-center"}>
                        <span className={"font-medium text-md"}>Folders</span>
                    </div>
                    <div className={"grid grid-cols-2 md:grid-cols-5 gap-4"}>
                        <Folder/>
                        <Folder/>
                        <Folder/>
                        <Folder/>
                        <Folder/>
                        <Folder/>
                        <Folder/>
                    </div>
                </div>

                <div>
                    <div className={"h-10 flex items-center"}>
                        <span className={"font-medium text-md"}>Files</span>
                    </div>
                    <div className={"w-full"}>
                        <FilesTable/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Folder = ({title, detail, add, link}) => {
    return (
        <div className={`flex flex-col gap-2 justify-between bg-white p-3 hover:bg-white/60 
        cursor-pointer rounded-md border`}>
            <div className={"flex flex-col"}>
                <IoFolderOpen className={"text-3xl text-theme-600"}/>
                <span className={"text-md"}>Folder Name</span>
            </div>
            <span className={"font-medium text-sm text-gray-400"}>
                   10 files
            </span>
        </div>
    )
}
export default FileManager;
