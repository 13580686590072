import React, { Fragment, useEffect, useState } from "react";
import {
    Table,
    TableHeader,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableFooter,
} from "../../../../../components/table/tableComponent";
import { GoTrash } from "react-icons/go";
import { RxPencil2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import DefaultPagination from "../../../../../components/table/pagination";
import { removeSession } from "../../../../../../store/slices/workspace.slice";
import { deleteSession } from "../../../../../../services/workspace/session.service";
import { Link } from "react-router-dom";
import ConfirmModal from "../../../../../components/modals/ConfirmModel";
import { getSessionList } from "../../../../../../services/workspace/session.service";
import toast from "react-hot-toast";
import TableSkeleton from "../../../../../components/skeleton/tableSkeleton";
import { dateFormat } from "../../../../../../utils/helper";

const TableHeaderData = [
    "Session Name", "Abbreviation", "From - To", "Action"
];

const SessionTable = ({ search }) => {
    const dispatch = useDispatch();
    const workspaceInfo = useSelector((state) => state.workspace.workspaceInfo);
    const { default_workspace } = workspaceInfo || {};
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState({ data: [], total: 0 })
    const [limit, setLimit] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [isOpen, setIsOpen] = useState(false)
    const [selectedId, setSelectedId] = useState(false)

    const onConfirm = () => {
        deleteSession(selectedId).then((response) => {
            dispatch(removeSession(selectedId))
            setTableData((prev) => ({ ...prev, data: prev.data.filter((item) => item.id != selectedId) }))
            toast.success("Session deleted successfully!")
        }).catch((error) => {
            toast.error("Failed to delete session.")
            console.log(error)
        })
        setIsOpen(false)
    }

    const handleDelete = (id) => {
        setSelectedId(id)
        setIsOpen(true)
    }

    const fetchData = () => {
        setLoading(true)
        getSessionList({ search: search, page: (search ? 1 : currentPage), limit: limit }).then((response) => {
            setLoading(false)
            setTableData(response)
            console.log(response)
        }).catch((error) => {
            toast.error("Failed to fetch session data!");
            console.log(error)
        })
    }

    useEffect(() => {
        const newTimer = setTimeout(() => fetchData(), search ? 500 : 0)
        return () => clearTimeout(newTimer)
    }, [search, currentPage])


    return (
        <div className="overflow-x-auto rounded-md shadow-sm">
            <Table className="w-full border-collapse bg-white text-left text-sm text-gray-500 rounded-md">
                <TableHeader>
                    <TableRow>
                        {TableHeaderData.map((header, i) => (
                            <TableHead key={i}>{header}</TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {loading ?
                        <TableRow>
                            <TableCell colSpan={4} className="text-center">
                                <TableSkeleton />
                            </TableCell>
                        </TableRow>
                        :
                        <Fragment>
                            {!!tableData.data.length ? tableData.data.map((item, i) => (
                                <TableRow key={i}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.abbr ? "-" : "-"}</TableCell>
                                    <TableCell>{dateFormat(item.session_start)} - {dateFormat(item.session_end)}</TableCell>
                                    <TableCell className="flex items-center gap-2 text-gray-400">
                                        <Link to={`/basic-setup/manage-sessions/edit/${item.id}`} className="text-xl">
                                            <RxPencil2 />
                                        </Link>
                                        <button onClick={() => handleDelete(item.id)} className="text-xl">
                                            <GoTrash />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            )) : (
                                <TableRow>
                                    <TableCell colSpan={4} className="text-center">
                                        No sessions found.
                                    </TableCell>
                                </TableRow>
                            )}

                            <TableFooter className="w-full bg-red-500">
                            </TableFooter>
                        </Fragment>
                    }
                </TableBody>
            </Table>

            {!!tableData.total && <DefaultPagination
                totalPages={tableData.total}
                currentPage={currentPage}
                limit={limit}
                setCurrentPage={setCurrentPage}
            />}
            <ConfirmModal
                isOpen={isOpen}
                title="Are you sure?"
                message="Do you really want ot delete this record? This process cannot be undone."
                onConfirm={onConfirm}
                onCancel={() => setIsOpen(false)}
            />
        </div>
    );
};

export default SessionTable;
