import DefaultButton from "../../../../components/buttons/defaultButton";
import {TbArrowsExchange} from "react-icons/tb";

const MyTimeTable = ({item}) => {
    const weekDays = [
        {day: "Mon", date: "22"},
        {day: "Tue", date: "23", active: true},
        {day: "Wed", date: "24"},
        {day: "Thu", date: "25"},
        {day: "Fri", date: "26"},
        {day: "Sat", date: "27"},
    ]

    return (
        <div className={"flex flex-col gap-2"}>
            <div className={"grid grid-cols-6 gap-2 border border-indigo-100 rounded-full p-1"}>
                {weekDays.map((item, index) => {
                    return (
                        <DayCard item={item} key={index}/>
                    )
                })}
            </div>
            <div className={"flex justify-between items-center"}>
                <span>Today</span>
                <DefaultButton
                    variant="primary"
                    name={"Exchange Request"}
                />
            </div>

            <ClassCard period={"Assembly"} time={"11:40AM to 12:20PM"} bg={"bg-cyan-100"}/>
            <ClassCard classNumber={"4A"} period={"Math"} time={"12:20PM to 01:20PM"} bg={"bg-indigo-100"}/>
            <ClassCard period={"Break"} time={"01:20PM to 02:20PM"} bg={"bg-pink-100"}/>
            <ClassCard classNumber={"4A"} period={"Biology"} time={"12:20PM to 01:20PM"} bg={"bg-yellow-100"}/>
            <ClassCard period={"Free Period"} time={"12:20PM to 01:20PM"} bg={"bg-red-100"}/>
            <ClassCard classNumber={"4A"} period={"Science"} exchange time={"12:20PM to 01:20PM"} bg={"bg-violet-100"}/>
        </div>
    )
}

const DayCard = (({item}) => {
    return (
        <div className={`flex flex-col items-center py-2 rounded-full text-xs hover:bg-indigo-50 cursor-pointer
        ${item?.active ? "bg-indigo-100 hover:bg-indigo-200" : ""}`}>
            <span className={"font-medium"}>{item.day}</span>
            <span>{item.date}</span>
        </div>
    )
})


const ClassCard = (({classNumber, period, time, bg, exchange}) => {
    return (
        <div className={`flex justify-between items-center py-4 rounded-md text-xs cursor-pointer
        ${bg ? bg : ""} px-4`}>
            <div className={"flex flex-col gap-1"}>
                {classNumber && <span className={"font-medium"}>Class {classNumber}</span>}
                {period && <span className={"font-medium"}>{period}</span>}
            </div>
            {exchange ?
                <div className={"flex gap-4 items-center"}>
                   <span className={"flex flex-col font-medium text-gray-500"}>
                       <span className={"font-medium"}>Sandeep Singh</span>
                       <span>Science</span>
                   </span>
                    <span className={"flex flex-col items-center text-theme-600 font-medium"}>
                       <TbArrowsExchange className={"text-xl"}/>
                       <span>Exchange With</span>
                    </span>
                    <span className={"flex flex-col font-medium text-gray-500"}>
                       <span className={"font-medium"}>Arsh Multani</span>
                       <span>Math</span>
                    </span>
                </div>
                :
                ""
            }
            <div>
            {time && <span>{time}</span>}
            </div>
        </div>
    )
})
export default MyTimeTable;
