import React from "react";
import FindStudentForm from "./components/findStudentForm";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const FindStudent = () => {
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Academic', url: "/academic"},
                    {label: 'Find Student'},
                ]}
            />
            <div className={"flex flex-col w-full gap-4 bg-white p-4 rounded-md"}>
                <FindStudentForm/>
            </div>
        </div>
    )
}
export default FindStudent;
