const MyClassTimeTable = ({item}) => {
    const weekDays = [
        {day: "Mon", date: "22"},
        {day: "Tue", date: "23", active: true},
        {day: "Wed", date: "24"},
        {day: "Thu", date: "25"},
        {day: "Fri", date: "26"},
        {day: "Sat", date: "27"},
    ]

    return (
        <div className={"grid grid-cols-6 gap-2 border border-indigo-100 rounded-full p-1"}>
            {weekDays.map((item, index) => {
                return (
                    <DayCard item={item} key={index}/>
                )
            })}
        </div>
    )
}

const DayCard = (({item}) => {
    return (
        <div className={`flex flex-col items-center py-2 rounded-full text-xs hover:bg-indigo-50 cursor-pointer
        ${item?.active ? "bg-indigo-100 hover:bg-indigo-200" : ""}`}>
            <span className={"font-medium"}>{item.day}</span>
            <span>{item.date}</span>
        </div>
    )
})
export default MyClassTimeTable;
