
"use client";

import { Pagination } from "flowbite-react";
import { useState } from "react";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

const DefaultPagination = ({ totalPages, currentPage, limit, setCurrentPage }) => {
    const onPageChange = (page) => setCurrentPage(page);

    return (
        <div className="w-full flex justify-between items-center px-4 py-2">
            <div>
                <span className="flex gap-1">
                    Showing
                    <b>{(currentPage - 1) * limit + 1}</b>
                    to
                    <b>{Math.min(currentPage * limit, totalPages)}</b>
                    of
                    <b>{totalPages}</b>
                    Entries
                </span>
            </div>
            <Pagination
                layout="pagination"
                style={{ display: "flex", alignItems: 'center', gap: '10px', }}
                currentPage={currentPage}
                totalPages={Math.ceil(totalPages / limit)}
                onPageChange={onPageChange}
                previousLabel={<PiCaretLeftBold />}
                nextLabel={<PiCaretRightBold />}
                showIcons={false}
            />
        </div>
    );
}

export default DefaultPagination;