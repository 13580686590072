import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import { useNavigate, useParams } from "react-router-dom";
import { createStaff, getStaffDetail, updateStaff } from "../../../../../services/workspace/staff.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { RadioInput } from "../../../../components/inputs/radioInput";
import ProfilePic from "../../../../components/inputs/profilePic";
import { SelectInput } from "../../../../components/inputs/selectInput";
import DefaultDatePicker from "../../../../components/inputs/defaultDatePicker";
import moment from "moment";
import { getDepartmentList } from "../../../../../services/workspace/department.service";
import { getDesignationList } from "../../../../../services/workspace/designation.service";
import { dateFormat } from "../../../../../utils/helper";
import { UploadInput } from "../../../../components/inputs/uploadInput";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";

export function AddStaff({}) {
    const teaching = [
        {id: "t1", name: "Yes", value: 1},
        {id: "t2", name: "No", value: 0},
    ]
    const genderData = [
        {id: "g1", name: "Male", value: "male"},
        {id: "g2", name: "Female", value: "female"},
    ]
    const marital = [
        {value:'single', label:"Single"},
        {value:'married', label:"Married"},
        {value:'divorced', label:"Divorced"},
        {value:'widowed', label:"Widowed"}
    ]

    const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [departments, setDepartments] = useState([])
    const [designations, setDesignations] = useState([])


    const fetchData = ()=>{
        Promise.all([
            getDepartmentList(),
            getDesignationList(),
        ]).then((response)=>{
            if(!id) setLoading(false)
            setDepartments(response[0].data.map((item)=>({value: item.id, label: item.name})))
            setDesignations(response[1].data.map((item)=>({value: item.id, label: item.name})))
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the department or designation data!")
            console.log(error)
        })
    }

    const fetchDetail = ()=>{
        getStaffDetail(id).then((response)=>{
            setLoading(false)
            setData(response)
            console.log(response)
        }).catch((error)=>{
            toast.error("Failed to fetch the staff data!")
            console.log(error)
        })
    }

    useEffect(()=>{
        fetchData()
        if(id) fetchDetail()
    },[id])

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().trim().required('First Name is required'),
        last_name: Yup.string().trim().required('Last Name is required'),
        father_name: Yup.string().trim().required('Father Name is required'),
        mother_name: Yup.string().trim().required('Mother Name is required'),
        marital_status: Yup.string().trim().required('Marital status is required'),
        partner_name: Yup.string().when('marital_status', {
            is: (value) => value == "married",
            then: (schema) => schema.required('Partner Name is required.'),
            otherwise: (schema) => schema.notRequired(),
        }),
        date_of_marriage: Yup.string().trim().when('marital_status', {
            is: (value) => value == "married",
            then: (schema) => schema.required('Date of marriage is required').test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid()),
            otherwise: (schema) => schema.notRequired(),
        }),       
        is_teacher: Yup.string().trim().required('Teacher employee is required'),
        emp_code: Yup.string().trim().required('Employee Code number is required'),
        emp_role: Yup.string().trim().required('Employee role number is required'),
        username: Yup.string().trim().required('Username is required'),
        // password: Yup.string().trim().required('Password is required'),
        email: Yup.string().trim().required('Email is required'),
        phone: Yup.string().trim().required('Mobile number is required'),
        // image: Yup.string().trim().required('Photo number is required'),
        gender: Yup.string().trim().required('Gender is required'),
        date_of_birth: Yup.string().trim().required('Date of birth is required')
                    .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid()),
        qualification: Yup.string().trim().required('Qualification is required'),
        department_id: Yup.number().required("Department is required.").typeError("Department must be selected."),
        designation_id: Yup.number().required("Designation is required.").typeError("Designation must be selected."),
        address: Yup.string().trim().required('Address is required'),
        country: Yup.string().trim().required('Country is required'),
        state: Yup.string().trim().required('State is required'),
        city: Yup.string().trim().required('City is required'),
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            first_name: data?.first_name || "",
            last_name: data?.last_name || "",
            father_name: data?.father_name || "",
            mother_name: data?.mother_name || "",
            partner_name: data?.partner_name || "",
            marital_status: data?.marital_status || "",
            date_of_marriage: dateFormat(data?.date_of_marriage) || "",
            is_teacher: data?.is_teacher || "",
            emp_code: data?.emp_code || "",
            emp_role: data?.emp_role || "",
            username: data?.user?.username || "",
            password: data?.password || "",
            email: data?.email || "",
            phone: data?.phone || "",
            image: data?.profile_image || "",
            gender: data?.gender || "",
            date_of_birth: dateFormat(data?.date_of_birth) || "",
            qualification: data?.qualification || "",
            department_id: data?.department_id || "",
            designation_id: data?.designation_id || "",
            address: data?.address || "",
            country: data?.country || "",
            state: data?.state || "",
            city: data?.city || "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            setIsSubmitting(true)     
            if(typeof(values.image)!="object") delete values.image;

            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            if(id){
                updateStaff(formData, id).then((response)=>{
                    toast.success("Staff updated successfully!")
                    navigate("/basic-setup/manage-staff")
                }).catch((error)=>{
                    toast.error("Failed to update staff!")
                    console.log(error)    
                })
            } else{
                createStaff(formData).then((response)=>{
                    toast.success("Staff created successfully!")
                    navigate("/basic-setup/manage-staff")
                }).catch((error)=>{
                    toast.error("Failed to create staff!")
                    console.log(error)
                })
            }
        }
    })


    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Basic Setup', url: "/basic-setup"},
                    {label: 'Manage staff',url: "/basic-setup/manage-staff"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"bg-white p-4 flex flex-col gap-4"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"flex flex-col gap-4 md:gap-8"}>
                        <div className="flex flex-col w-32 gap-4 justify-center items-center">
                            <ProfilePic
                                picture={typeof(values.image)=="object"? URL.createObjectURL(values.image) : values.image}
                            />
                            <UploadInput
                                id="image"
                                name="image"
                                label="Choose Profile Pic"
                                onChange={(e)=>setFieldValue("image", e.target.files[0])}
                                onBlur={handleBlur}
                                error={touched.image && errors.image ? errors.image : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"Username"} 
                                placeholder={"username"}
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.username && errors.username ? errors.username : ''}
                            />
                            <DefaultInput 
                                label={"Password"} 
                                placeholder={"Password"}
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.password && errors.password ? errors.password : ''}
                            />
                            <DefaultInput 
                                label={"Email Address"}
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && errors.email ? errors.email : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <SelectInput
                                label={"Department"}
                                name="department_id"
                                value={values.department_id}
                                options={departments}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.department_id && errors.department_id ? errors.department_id : ''}
                            />
                            <SelectInput
                                label={"Designation"}
                                name="designation_id"
                                value={values.designation_id}
                                options={designations}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.designation_id && errors.designation_id ? errors.designation_id : ''}
                            />
                            <DefaultInput 
                                label={"Employee First Name"} 
                                placeholder={"Employee First Name"}
                                name="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.first_name && errors.first_name ? errors.first_name : ''}
                            />
                            <DefaultInput 
                                label={"Employee Last Name"} 
                                placeholder={"Employee Last Name"}
                                name="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.last_name && errors.last_name ? errors.last_name : ''}
                            />
                            <DefaultInput 
                                label={"Employee Role Number"} 
                                placeholder={"Employee Role Number"}
                                name="emp_role"
                                value={values.emp_role}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.emp_role && errors.emp_role ? errors.emp_role : ''}
                            />
                            <DefaultInput 
                                label={"Employee Code"} 
                                placeholder={"Employee Code"}
                                name="emp_code"
                                value={values.emp_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.emp_code && errors.emp_code ? errors.emp_code : ''}
                            />
                            <RadioInput 
                                legend={"Teaching Employee"} 
                                horizontal 
                                data={teaching}
                                name="is_teacher"
                                value={values.is_teacher}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.is_teacher && errors.is_teacher ? errors.is_teacher : ''}
                            />
                            <RadioInput 
                                legend={"Gender"} 
                                horizontal 
                                data={genderData}
                                name="gender"
                                value={values.gender}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.gender && errors.gender ? errors.gender : ''}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultDatePicker
                                label={'Date of birth'}
                                name="date_of_birth"
                                onChange={(data) => setFieldValue('date_of_birth',data)}
                                value={values.date_of_birth}
                                placeholder={"12/01/2020"}
                                onBlur={handleBlur}
                                error={touched.date_of_birth && errors.date_of_birth ? errors.date_of_birth : ''}
                            />
                            <SelectInput
                                label={"Marital Status"}
                                name="marital_status"
                                value={values.marital_status}
                                options={marital}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.marital_status && errors.marital_status ? errors.marital_status : ''}
                            />

                            {values.marital_status == "married" && <>
                                <DefaultDatePicker
                                    label={'Date of marriage'}
                                    name="date_of_marriage"
                                    onChange={(data) => setFieldValue('date_of_marriage',data)}
                                    value={values.date_of_marriage}
                                    placeholder={"12/01/2020"}
                                    onBlur={handleBlur}
                                    error={touched.date_of_marriage && errors.date_of_marriage ? errors.date_of_marriage : ''}
                                />
                                <DefaultInput 
                                    label={"Partner name"}
                                    name="partner_name"
                                    value={values.partner_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.partner_name && errors.partner_name ? errors.partner_name : ''}
                                />
                            </>}
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-4 gap-4"}>
                            <DefaultInput 
                                label={"Qualification"}
                                name="qualification"
                                value={values.qualification}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.qualification && errors.qualification ? errors.qualification : ''}
                            />
                            <DefaultInput 
                                label={"Father Name"}
                                name="father_name"
                                value={values.father_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.father_name && errors.father_name ? errors.father_name : ''}
                            />
                            <DefaultInput 
                                label={"Mother Name"}
                                name="mother_name"
                                value={values.mother_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.mother_name && errors.mother_name ? errors.mother_name : ''}
                            />
                            <DefaultInput 
                                label={"Mobile Number"}
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.phone && errors.phone ? errors.phone : ''}
                            />
                            <DefaultInput 
                                label={"Address"} 
                                placeholder={"address"}
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.address && errors.address ? errors.address : ''}
                            />
                            <DefaultInput 
                                label={"Country"} 
                                placeholder={"country"}
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.country && errors.country ? errors.country : ''}
                            />
                            <DefaultInput 
                                label={"State"} 
                                placeholder={"state"}
                                name="state"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.state && errors.state ? errors.state : ''}
                            />
                            <DefaultInput 
                                label={"City"} 
                                placeholder={"city"}
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.city && errors.city ? errors.city : ''}
                            />
                        </div>
                    </div>
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    );
}
