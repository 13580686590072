import { configureStore } from '@reduxjs/toolkit';
import userReducer, { initialState as userInitial } from './slices/user.slice';
import workspaceReducer, { initialState as workspaceInitial } from './slices/workspace.slice';
import { getItem, setItem } from '../utils/localStorage';

const store = configureStore({
  reducer: {
    user: userReducer,
    workspace: workspaceReducer,
  },
  preloadedState: {
    user: {
      ...userInitial,
      userInfo: getItem('user')
    },
    workspace: {
      ...workspaceInitial,
      workspaceInfo: getItem('workspace')
    }
  },
});

store.subscribe(() => {
  setItem('user', store.getState().user.userInfo);
  setItem('workspace', store.getState().workspace.workspaceInfo);
});

export default store;
