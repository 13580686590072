import {Dropdown} from "flowbite-react";
import {Link} from "react-router-dom";
import {IoIosArrowForward, IoMdAdd} from "react-icons/io";
import {MdKeyboardCommandKey} from "react-icons/md";
import {BsShift} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { switchWorkspace } from "../../../../../services/auth/auth.service";
import toast from "react-hot-toast";
import { initialData } from "../../../../../store/slices/workspace.slice";
export function WorkspaceDropdown({label, trigger, dropdownData}) {
    const dispatch = useDispatch()
    const workspaceInfo = useSelector((state) => state.workspace.workspaceInfo);
    const {workspaces, permissions} = workspaceInfo;

    const handleSwitch = (workspace_id)=>{
        if(workspace_id!=workspaceInfo.default_workspace.id){
            switchWorkspace({workspace_id: workspace_id}).then((response)=>{
                toast.success("Workspace switched successfully!")
                setTimeout(()=>window.location.reload(),800)
                console.log(response)
            }).catch((error)=>{
                toast.error("Failed to switch workspace!")
                console.log(error)
            })
        }
    }
    return (
        <Dropdown
            label={label ? label : ""}
            className={"hover:border border border-gray-100 w-[400px] max-h-[calc(100vh-100px)] bg-white pb-4"}
            renderTrigger={() => trigger}
            dismissOnClick={true}
            placement="right-start"
        >
            <div className={"flex flex-col"}>
                {workspaces.map((workspace, index) => {
                    return (
                        <Dropdown.Item  key={index}>
                            <div className={"flex justify-between items-start w-full cursor-pointer gap-2 p-2"} onClick={()=>handleSwitch(workspace.id)}>
                                    <div className={"flex items-center gap-2"} key={index}>
                                        <div className={"w-10 h-10 rounded-md bg-gray-600 flexCenter text-white"}>
                                            S
                                        </div>
                                        <div className={"flex flex-col justify-cente items-start"}>
                                            <span className="font-medium text-sm">{workspace.name}</span>
                                            <span className="text-sm">{workspace.address || 'No Address'}</span>
                                        </div>
                                    </div>
                                    {workspace.active &&
                                        <span
                                            className={`truncate text-sm font-normal flex items-center gap-1 text-gray-600`}>
                                                <div className={"w-2 h-2 rounded-full bg-green-600"}/>Active
                                            </span>
                                    }
                            </div>
                        </Dropdown.Item>
                    )
                })}
                <div className={"flex justify-between items-center gap-2 p-2"}>
                    <div className={"h-10 rounded-md flexCenter text-gray-600 gap-1 cursor-pointer"}>
                        Add New Workspace <IoMdAdd/>
                    </div>
                </div>

                <div className={"flex flex-col gap-2"}>
                    <div className={"h-10 flex justify-between items-center border-t border-gray-200 px-2"}>
                        Preferences
                       <div className={"flex items-center"}>
                           <MdKeyboardCommandKey/> R
                       </div>
                    </div>
                    <div className={"h-10 flex justify-between items-center border-t border-gray-200 px-2"}>
                        Settings & Administration
                        <div className={"flex items-center"}>
                        <BsShift/> S
                        </div>
                    </div>
                    <div className={"h-10 flex justify-between items-center border-t border-gray-200 px-2 text-red-600"}>
                        Sign out of Synswift
                    </div>
                </div>
            </div>
        </Dropdown>
    );
}

