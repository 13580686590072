import {Fragment} from "react";
import {Drawer} from "flowbite-react";
import DefaultInput from "../../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../../components/buttons/defaultButton";
import {SelectInput} from "../../../../../components/inputs/selectInput";

export function AddFine({isOpen, handleClose}) {

    return (
        <Fragment>
            <Drawer open={isOpen} onClose={handleClose} position="right" className={"p-0 w-300"}>
                <div className={"p-4 border-b border-gray-200"}>
                    <span className={"font-medium text-md"}>Add Fine</span>
                </div>
                <div className={"flex flex-col p-4 h-[calc(100vh-122px)] overflow-y-auto"}>
                    <div className={"grid grid-cols-1 gap-4"}>
                        <SelectInput label={"Fine Type"} options={[]}/>
                        <DefaultInput label={"Fine Name"}/>
                        <DefaultInput label={"Fine Amount (Rs.)"}/>
                        <DefaultInput label={"Fine Cycle"}/>
                        <DefaultInput label={"Student Registration Number"}/>
                    </div>
                </div>
                <div className={"p-4 flex justify-end"}>
                    <DefaultButton
                        variant="primary"
                        name={"Submit"}
                    />
                </div>
            </Drawer>
        </Fragment>
    );
}
