import {useState} from "react";
import {AddConcession} from "./components/addConcession";
import ConcessionTable from "./components/table";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const ApplyConcessions = () => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);

    return (
        <div className={"flex flex-col gap-4"}><PageTableHeader
            breadcrumbs={[
                {label: 'Fee', url: "/fee"},
                {label: 'Apply Concession'},
            ]}
        />
            <AddConcession isOpen={isOpen} handleClose={handleClose}/>
            <ConcessionTable/>
        </div>
    )
}
export default ApplyConcessions;
