import {useFormik} from "formik";
import {useState} from "react";
import * as Yup from 'yup';
import DefaultInput from "../inputs/defaultInput";
import DefaultButton from "../buttons/defaultButton";
import {createSession, updateSession} from "../../../services/workspace/session.service";
import {useDispatch} from "react-redux";
import {addSession, editSession, initialData} from "../../../store/slices/workspace.slice";
import DefaultDatePicker from "../inputs/defaultDatePicker";
import moment from "moment";
import { dateFormat } from "../../../utils/helper";
import toast from "react-hot-toast";

const SessionForm = ({setShowForm, sessionData, setSessionData}) => {
    const [creating, setCreating] = useState(false);
    const dispatch = useDispatch();


    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .required('Name is required'),
        session_start: Yup.string()
            .required('Session start date is required')
            .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid()),
        session_end: Yup.string()
            .required('Session end date is required')
            .test('isValid', 'Invalid date format, expected DD/MM/YYYY', value => moment(value, 'DD/MM/YYYY', true).isValid())
            .test('isAfterStart', 'Session end date must be later than session start date', function(value) {
                const { session_start } = this.parent;
                return moment(value, 'DD/MM/YYYY').isAfter(moment(session_start, 'DD/MM/YYYY'));
            }),
    });

    const { 
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        setFieldValue,
        errors
    } = useFormik({
        initialValues: {
            name: sessionData?.name || '',
            session_start: dateFormat(sessionData?.session_start) || '',
            session_end: dateFormat(sessionData?.session_end) || '',
        },
        validationSchema: validationSchema,
        enableReinitialize: true,   
        onSubmit: async (values) => {
            console.log(values)
            if(sessionData?.id){
                updateSession(values, sessionData.id).then((response)=>{
                    toast.success("Session updated successfully!")
                    dispatch(editSession(response))
                }).catch((error)=>{
                    toast.error("Failed to update session!")
                    console.log(error)
                })
            }else{
                createSession(values).then((response)=>{
                    toast.success("Session created successfully!")
                    dispatch(addSession(response))
                }).catch((error)=>{
                    toast.error("Failed to create session!")
                    console.log(error)
                })
            }
            setSessionData()
            setShowForm(false)
        },
    });

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <div className="flex flex-col px-4">
                <DefaultInput
                    error={touched.name && errors.name ? errors.name : ''}
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    placeholder={"Session Name..."}
                    label={'Session Name'}
                />
                <DefaultDatePicker
                    error={touched.session_start && errors.session_start ? errors.session_start : ''}
                    name="session_start"
                    onChange={(data) => setFieldValue('session_start',data)}
                    value={values.session_start}
                    placeholder={"12/01/2020"}
                    label={'Start Date'}
                />
               <DefaultDatePicker
                    error={touched.session_end && errors.session_end ? errors.session_end : ''}
                    name="session_end"
                    onChange={(data) => setFieldValue('session_end',data)}
                    value={values.session_end}
                    placeholder={"12/01/2020"}
                    label={'End Date'}
                />
            </div>
            <div className="flex flex-row gap-3 border-t border-gray-200 px-4 pt-2">
                <DefaultButton
                    onClick={() => {
                        setSessionData()
                        setShowForm(false)
                    }}
                    name="Back"
                    variant="outline"
                />
                <DefaultButton
                    loading={creating}
                    type="submit"
                    name="Create"
                    variant="primary"
                />
            </div>
        </form>
    );
}

export default SessionForm;
