import React from "react";
import PrintReceiptForm from "./components/printReceiptForm";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

const PrintReceipt = () => {
    return (
        <div className={"flex flex-col gap-4 theme-container"}> <PageTableHeader
            breadcrumbs={[
                {label: 'Fee', url: "/fee"},
                {label: 'Print Receipt'},
            ]}
        />
            <div className={"flex flex-col w-full gap-4 bg-white p-4 rounded-md"}>
                <PrintReceiptForm/>
            </div>
        </div>
    )
}
export default PrintReceipt;
