import React, {Fragment} from "react";
import {Drawer} from "flowbite-react";
import {Link, useLocation} from "react-router-dom";

export function MenuDrawer({isOpen, handleClose, menu, teacherMenu}) {

    return (
        <Fragment>
            <Drawer open={isOpen} onClose={handleClose} position="right" className={"p-0 w-200"}>
                <div className={"p-4 border-b border-gray-200"}>
                    <span className={"font-medium text-md"}>Synswift School</span>
                </div>
                <div className={"flex flex-col p-4 overflow-y-auto"}>
                    <div className={"flex flex-col gap-2 p-2"}>
                        {menu.map((item, index) => (
                            <Menu
                                key={index}
                                icon={item.icon}
                                path={item.path}
                                label={item.label}
                            />
                        ))}

                        <span>Teacher Menu</span>
                        {teacherMenu.map((item, index) => (
                            <Menu
                                key={index}
                                icon={item.icon}
                                path={item.path}
                                label={item.label}
                            />
                        ))}
                    </div>
                </div>
            </Drawer>
        </Fragment>
    );
}


const Menu = ({icon, path, label}) => {
    const location = useLocation();
    const isActive = path === "/" ? location.pathname === path : location.pathname.includes(path);
    const activeClasses = 'bg-gray-100 hover:bg-gray-200 text-theme-600';
    const inactiveClasses = 'text-gray-600 hover:bg-gray-50';
    return (
        <Link
            to={path}
            className={`text-md flex items-center gap-4 h-8 px-2 rounded-md font-medium
            ${isActive ? activeClasses : inactiveClasses}`}
        >
            {icon ? icon : ""}
            <span className={"text-sm"}>{label}</span>
        </Link>
    );
};
