import {Outlet} from "react-router-dom";

const TeacherRoute = () => {
    return (
        <div className={"p-4"}>
            <Outlet/>
        </div>
    )
}
export default TeacherRoute;
