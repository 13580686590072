import TableSkeleton from "../../../components/skeleton/tableSkeleton";
import NoData from "../../../components/noData/noData";


const AssignmentsTable = ({loading, tableData}) => {
    return (
        <div className={"flex flex-col gap-2"}>
           <span className={"font-medium"}>
               Assignments
           </span>
            <div className={"p-2 bg-white rounded-lg h-full border border-gray-200 flex flex-col gap-2"}>
                <div className="overflow-x-auto rounded-md shadow-sm">
                    <table className="w-full border-collapse bg-white text-left text-sm text-gray-500 rounded-md">
                        <thead className="bg-gray-200 rounded-t-md">
                        <tr>
                            <th scope="col" className="px-4 py-2 font-medium text-gray-900">
                                Class
                            </th>
                            <th scope="col" className="px-4 py-2 font-medium text-gray-900">
                                Abbreviation
                            </th>
                            <th scope="col" className="px-4 py-2 font-medium text-gray-900">
                                Capacity
                            </th>
                            <th
                                scope="col"
                                className="px-4 py-2 font-medium text-gray-900 hidden md:table-cell"
                            >
                                Status
                            </th>
                            <th
                                scope="col"
                                className="px-4 py-2 font-medium text-gray-900 hidden md:table-cell"
                            >
                                Gender
                            </th>
                            <th
                                scope="col"
                                className="px-4 py-2 font-medium text-gray-900 hidden md:table-cell"
                            >
                                Class Teacher
                            </th>
                            <th
                                scope="col"
                                className="px-4 py-2 font-medium text-gray-900 hidden md:table-cell"
                            >
                                Subject
                            </th>
                            <th
                                scope="col"
                                className="px-4 py-2 font-medium text-gray-900 hidden md:table-cell"
                            >
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                        {loading ? (
                            <tr>
                                <td colSpan={8} className="text-center">
                                    <TableSkeleton/>
                                </td>
                            </tr>
                        ) : tableData && tableData.length > 0 ? (
                            tableData.map((item, index) => (
                                <tr className="hover:bg-gray-50" key={index}>
                                    <td className="px-4 py-4">{index + 1}</td>
                                    <td className="px-4 py-4">
                                        <div className="font-medium text-gray-700">
                                            {item.name || ""}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4">
                                        <div className="font-medium text-gray-700">
                                            {item.dob || ""}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4">
                                        <div className="font-medium text-gray-700">
                                            {item.joining || ""}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4 hidden md:table-cell">
                                        <div className="font-medium text-gray-700">
                                            {item.subjects || ""}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4">
                                        <div className="font-medium text-gray-700">
                                            {item.experience || ""}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4 hidden md:table-cell">
                                        <div className="font-medium text-gray-700">
                                            {item.status || ""}
                                        </div>
                                    </td>
                                    <td className="px-4 py-4 hidden md:table-cell">
                                        <div className="font-medium text-gray-700">
                                            {item.actions || ""}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={8} className="text-center">
                                    <NoData/>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default AssignmentsTable;
