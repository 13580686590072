import React from "react";
import BlockEditor from "../../../components/blockEditor/blockEditor";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";

export function AddTemplate({}) {
    return (
        <div className={"flex flex-col gap-2"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Configuration', url: "/configuration"},
                    {label: 'Manage Templates', url: "/configuration/manage-templates"},
                    {label: 'Add',},
                ]}
            />
            <div className={"flex flex-col w-full gap-4 bg-white px-2 rounded-md"}>
                <BlockEditor/>
            </div>
        </div>
    );
}
