import axios from "axios";
import { API_URL } from "./constants";
import toast from '../utils/toast'
import { getItem } from "../utils/localStorage";

const Axios = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
Axios.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      // "ngrok-skip-browser-warning": "69420",
      Authorization: `Bearer ${getItem('user')?.token}`,
    };

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  function (response) {
    // For status code with 2xx
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      toast.error("You are unauthorized please login again.");
    }
    return Promise.reject(error);
  }
);

export default Axios;
