import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import {RadioInput} from "../../../../components/inputs/radioInput";
import {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { createSubject, getSubjectDetail, updateSubject } from "../../../../../services/workspace/subject.service";
import * as Yup from "yup";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";

export function AddSubject() {
    const grade = [
        {id: "1", name: "Yes", value: "yes"},
        {id: "2", name: "No", value: "no"},
    ]

    const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    useEffect(()=>{
        if(id){
            setLoading(true)
            getSubjectDetail(id).then((response)=>{
                setLoading(false)
                setData(response)
                console.log(response)
            }).catch((error)=>{
                toast.error("Failed to fetch the subjects data!")
                console.log(error)
            })
        }
    },[id])

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Name is required'),
        code: Yup.string().trim().required('Code is required'),
        description: Yup.string().trim().required('Description is required'),
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            name: data?.name || "",
            code: data?.code || "",
            description: data?.description || "",
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            setIsSubmitting(true)     
            if(id){
                updateSubject(values, id).then((response)=>{
                    toast.success("Subject updated successfully!")
                    navigate("/basic-setup/manage-subjects")
                }).catch((error)=>{
                    toast.error("Failed to update subject!")
                    console.log(error)    
                })
            } else{
                createSubject(values).then((response)=>{
                    toast.success("Subject created successfully!")
                    navigate("/basic-setup/manage-subjects")
                }).catch((error)=>{
                    toast.error("Failed to create subject!")
                    console.log(error)
                })
            }
        }
    })

    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Basic Setup', url: "/basic-setup"},
                    {label: 'Manage subjects',url: "/basic-setup/manage-subjects"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"bg-white p-4 flex flex-col gap-4"}>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                    <div className={"flex flex-col overflow-y-auto gap-4 md:gap-8"}>
                        <div className={"grid grid-cols-1 gap-4"}>
                            <DefaultInput 
                                label={"Name"} 
                                placeholder={"name"}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.name && errors.name ? errors.name : ''}
                            />
                            <DefaultInput 
                                label={"Code"} 
                                placeholder={"code"}
                                name="code"
                                value={values.code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.code && errors.code ? errors.code : ''}
                            />
                            <DefaultInput 
                                label={"Description"} 
                                placeholder={"description"}
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.description && errors.description ? errors.description : ''}
                            />
                            <DefaultInput label={"Taught in class(es)"}/>
                            <DefaultInput label={"Exclude from examination"}/>
                            <RadioInput horizontal data={grade} legend={"Considered for final grade calculation"}/>
                        </div>
                    </div>
                    <div>
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    );
}
