import {Tabs} from "flowbite-react";
import MyTimeTable from "./myTimeTable";
import MyClassTimeTable from "./myClassTimeTable";

const TimeTableTabs=()=>{
    return(
        // eslint-disable-next-line react/style-prop-object
        <Tabs aria-label="Default tabs" style="fullWidth">
            <Tabs.Item active title="My Time Table">
               <MyTimeTable/>
            </Tabs.Item>
            <Tabs.Item title="My Class Time Table">
               <MyClassTimeTable/>
            </Tabs.Item>
        </Tabs>
    )
}
export default TimeTableTabs;
