import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";

// Routes
import AuthRoute from "./views/routes/authRoute";
import MainRoute from "./views/routes/mainRoute";

// Auth Pages
import Login from "./views/auth/login/login";

// Pages
import Dashboard from "./views/main/home/page";

// Basic Setup Pages
import BasicSetupRoute from "./views/main/basicSetup/page";
import BasicSetup from "./views/main/basicSetup/innerScreens/mainPage/page";
import ManageSessions from "./views/main/basicSetup/innerScreens/manageSessions/page";
import {AddSession} from "./views/main/basicSetup/innerScreens/manageSessions/add";
import ManageDepartment from "./views/main/basicSetup/innerScreens/manageDepartment/page";
import {AddDepartment} from "./views/main/basicSetup/innerScreens/manageDepartment/add";
import ManageDesignation from "./views/main/basicSetup/innerScreens/manageDesignation/page";
import {AddDesignation} from "./views/main/basicSetup/innerScreens/manageDesignation/add";
import ManageRoles from "./views/main/basicSetup/innerScreens/manageRoles/page";
import {AddRole} from "./views/main/basicSetup/innerScreens/manageRoles/add";
import ManageStaff from "./views/main/basicSetup/innerScreens/manageStaff/page";
import {AddStaff} from "./views/main/basicSetup/innerScreens/manageStaff/add";
import ManageClasses from "./views/main/basicSetup/innerScreens/manageClasses/page";
import ManageSubjects from "./views/main/basicSetup/innerScreens/manageSubjects/page";
import ManageMessageInbox from "./views/main/basicSetup/innerScreens/manageMessageInbox/page";
import ManageLeaveSetup from "./views/main/basicSetup/innerScreens/manageLeaveSetup/page";
import ManageStudentLeave from "./views/main/basicSetup/innerScreens/manageStudentsLeave/page";

// // Academic Pages
import AcademicRoute from "./views/main/academic/page";
import AcademicSetup from "./views/main/academic/innerScreens/mainPage/page";
import AdmitStudent from "./views/main/academic/innerScreens/admitStudent/page";
import StudentPersonalDetail from "./views/main/academic/innerScreens/admitStudent/profilePages/studentPersonalDetail";
import StudentParentDetail from "./views/main/academic/innerScreens/admitStudent/profilePages/studentParentDetail";
import StudentAdditionalDetail
    from "./views/main/academic/innerScreens/admitStudent/profilePages/studentAdditionalDetail";
import FindStudent from "./views/main/academic/innerScreens/findStudent/page";
import ApproveProfile from "./views/main/academic/innerScreens/approveProfile/page";
import AssignRollNumber from "./views/main/academic/innerScreens/assignRollNumber/page";
import PromoteStudent from "./views/main/academic/innerScreens/promoteStudent/page";

// // Fees Pages
import FeeRoute from "./views/main/fees/page";
import FeeSetup from "./views/main/fees/innerScreens/mainPage/page";
import ConfigurationSetupRoute from "./views/main/configuration/page";
import FeeCycle from "./views/main/fees/innerScreens/feeCycle/page";
import CreateFeeHead from "./views/main/fees/innerScreens/createFeeHead/page";
import AssignFeeHead from "./views/main/fees/innerScreens/assignFeeHead/page";
import CollectFees from "./views/main/fees/innerScreens/collectFees/page";
import PrintReceipt from "./views/main/fees/innerScreens/printReceipt/page";
import ApplyConcessions from "./views/main/fees/innerScreens/applyConcessions/page";
import RemoveConcessions from "./views/main/fees/innerScreens/removeConcessions/page";
import ApplyFine from "./views/main/fees/innerScreens/applyFine/page";
import CancelledReceipts from "./views/main/fees/innerScreens/cancelledReceipts/page";
import FeeWaiver from "./views/main/fees/innerScreens/feeWaiver/page";

// // File Manager Pages
import FileManagerRoute from "./views/main/fileManager/page";
import FileManager from "./views/main/fileManager/innerScreens/mainPage/mainPage";

// // Settings Pages
import Settings from "./views/main/settings/page";

// // Teacher Pages
import TeacherRoute from "./views/main/teacher/page";
import TeacherDashboard from "./views/main/teacher/home/page";
import TimeTable from "./views/main/teacher/timeTable/page";
import Gallery from "./views/main/teacher/gallery/page";
import Messages from "./views/main/teacher/messages/page";
import Profile from "./views/main/teacher/profile/page";

// Configration Setup
import ConfigurationSetup from "./views/main/configuration/innerScreens/mainPage/page";
import ManageTemplates from "./views/main/configuration/innerScreens/manageTemplates/page";
import {AddTemplate} from "./views/main/configuration/innerScreens/manageTemplates/add";
import {AddFeeCycle} from "./views/main/fees/innerScreens/feeCycle/add";
import {Provider} from 'react-redux';
import store from './store/store';
import { AddClass } from './views/main/basicSetup/innerScreens/manageClasses/addClass';
import { AddSection } from './views/main/basicSetup/innerScreens/manageSections/addSection';
import ManageSections from './views/main/basicSetup/innerScreens/manageSections/page';
import { AddSubject } from './views/main/basicSetup/innerScreens/manageSubjects/addSubject';
import { AddMessage } from './views/main/basicSetup/innerScreens/manageMessageInbox/addMessage';
import { AddLeaveSetup } from './views/main/basicSetup/innerScreens/manageLeaveSetup/addLeaveSetup';
import { AddStudentLeave } from './views/main/basicSetup/innerScreens/manageStudentsLeave/addStudentLeave';
import AddPreAdmissionEnquiry from './views/main/academic/innerScreens/preAdmissionEnquiry/addPreAdmissionEnquiry';
import ManagePreAdmissionEnquiry from './views/main/academic/innerScreens/preAdmissionEnquiry/page';
import AddStudent from './views/main/academic/innerScreens/admitStudent/addStudent';
import ManageHolidays from './views/main/academic/innerScreens/manageHolidays/page';
import AddHoliday from './views/main/academic/innerScreens/manageHolidays/addHoliday';
import ManageTimetables from './views/main/academic/innerScreens/manageTimetables/page';
import AddTimetable from './views/main/academic/innerScreens/manageTimetables/addTimetable';

const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    {/*Auth*/}
                    <Route path="/auth" element={<AuthRoute/>}>
                        <Route path={"/auth/login"} element={<Login/>}/>
                    </Route>

                    {/*Main*/}
                    <Route path="/" element={<MainRoute/>}>
                        {/*Dashboard*/}
                        <Route path={"/"} element={<Dashboard/>}/>

                        {/*Basic Setup*/}
                        <Route path={"/basic-setup"} element={<BasicSetupRoute/>}>
                            <Route path={"/basic-setup"} element={<BasicSetup/>}/>
                            <Route path={"/basic-setup/manage-sessions"} element={<ManageSessions/>}/>
                            <Route path="/basic-setup/manage-sessions/:action/:id?" element={<AddSession />} />
                            <Route path={"/basic-setup/manage-departments"} element={<ManageDepartment/>}/>
                            <Route path={"/basic-setup/manage-departments/:action/:id?"} element={<AddDepartment/>}/>
                            <Route path={"/basic-setup/manage-designations"} element={<ManageDesignation/>}/>
                            <Route path={"/basic-setup/manage-designations/:action/:id?"} element={<AddDesignation/>}/>
                            <Route path={"/basic-setup/manage-roles"} element={<ManageRoles/>}/>
                            <Route path={"/basic-setup/manage-roles/:action/:id?"} element={<AddRole/>}/>
                            <Route path={"/basic-setup/manage-staff"} element={<ManageStaff/>}/>
                            <Route path={"/basic-setup/manage-staff/:action/:id?"} element={<AddStaff/>}/>
                            <Route path={"/basic-setup/manage-sections"} element={<ManageSections/>}/>
                            <Route path={"/basic-setup/manage-sections/:action/:id?"} element={<AddSection/>}/>
                            <Route path={"/basic-setup/manage-classes"} element={<ManageClasses/>}/>
                            <Route path={"/basic-setup/manage-classes/:action/:id?"} element={<AddClass/>}/>
                            <Route path={"/basic-setup/manage-subjects"} element={<ManageSubjects/>}/>
                            <Route path={"/basic-setup/manage-subjects/:action/:id?"} element={<AddSubject/>}/>
                            <Route path={"/basic-setup/manage-message-inbox"} element={<ManageMessageInbox/>}/>
                            <Route path={"/basic-setup/manage-message-inbox/:action/:id?"} element={<AddMessage/>}/>
                            <Route path={"/basic-setup/manage-leave-setup"} element={<ManageLeaveSetup/>}/>
                            <Route path={"/basic-setup/manage-leave-setup/:action/:id?"} element={<AddLeaveSetup/>}/>
                            <Route path={"/basic-setup/manage-student-leave"} element={<ManageStudentLeave/>}/>
                            <Route path={"/basic-setup/manage-student-leave/:action/:id?"} element={<AddStudentLeave/>}/>
                        </Route>

                        {/*Academic*/}
                        <Route path={"/academic"} element={<AcademicRoute/>}>
                            <Route path={"/academic"} element={<AcademicSetup/>}/>
                            <Route path={"/academic/pre-admission-enquiry"} element={<ManagePreAdmissionEnquiry/>}/>
                            <Route path={"/academic/pre-admission-enquiry/:action/:id?"} element={<AddPreAdmissionEnquiry/>}/>
                            <Route path={"/academic/admit-student"} element={<AdmitStudent/>}/>
                            <Route path={"/academic/admit-student/:action/:id?"} element={<AddStudent/>}/>
                            <Route path={"/academic/manage-holidays"} element={<ManageHolidays/>}/>
                            <Route path={"/academic/manage-holidays/:action/:id?"} element={<AddHoliday/>}/>
                            <Route path={"/academic/manage-timetables"} element={<ManageTimetables/>}/>
                            <Route path={"/academic/manage-timetables/:action/:id?"} element={<AddTimetable/>}/>
                            <Route path={"/academic/admit-student/student"} element={<StudentPersonalDetail/>}/>
                            <Route path={"/academic/admit-student/parent"} element={<StudentParentDetail/>}/>
                            <Route path={"/academic/admit-student/additional"} element={<StudentAdditionalDetail/>}/>
                            <Route path={"/academic/approve-profile"} element={<ApproveProfile/>}/>
                            <Route path={"/academic/find-student"} element={<FindStudent/>}/>
                            <Route path={"/academic/assign-roll-number"} element={<AssignRollNumber/>}/>
                            <Route path={"/academic/promote-student"} element={<PromoteStudent/>}/>
                        </Route>

                        {/*Fee*/}
                        <Route path={"/fee"} element={<FeeRoute/>}>
                            <Route path={"/fee"} element={<FeeSetup/>}/>
                            <Route path={"/fee/fee-cycle"} element={<FeeCycle/>}/>
                            <Route path={"/fee/fee-cycle/add"} element={<AddFeeCycle/>}/>
                            <Route path={"/fee/create-fee-head"} element={<CreateFeeHead/>}/>
                            <Route path={"/fee/assign-fee-head"} element={<AssignFeeHead/>}/>
                            <Route path={"/fee/collect-fee"} element={<CollectFees/>}/>
                            <Route path={"/fee/print-receipt"} element={<PrintReceipt/>}/>
                            <Route path={"/fee/apply-concessions"} element={<ApplyConcessions/>}/>
                            <Route path={"/fee/remove-concessions"} element={<RemoveConcessions/>}/>
                            <Route path={"/fee/apply-fine"} element={<ApplyFine/>}/>
                            <Route path={"/fee/cancelled-receipts"} element={<CancelledReceipts/>}/>
                            <Route path={"/fee/fee-weiver"} element={<FeeWaiver/>}/>
                        </Route>

                        {/*Configuration*/}
                        <Route path={"/configuration"} element={<ConfigurationSetupRoute/>}>
                            <Route path={"/configuration"} element={<ConfigurationSetup/>}/>
                            <Route path={"/configuration/manage-Templates"} element={<ManageTemplates/>}/>
                            <Route path={"/configuration/manage-Templates/add"} element={<AddTemplate/>}/>
                        </Route>

                        {/*Settings*/}
                        <Route path={"/file-manager"} element={<FileManagerRoute/>}>
                            <Route path={"/file-manager"} element={<FileManager/>}/>
                        </Route>

                        {/*Settings*/}
                        <Route path={"/settings"} element={<Settings/>}/>

                        {/*Teacher*/}
                        <Route path="/teacher" element={<TeacherRoute/>}>
                            <Route path={"/teacher/dashboard"} element={<TeacherDashboard/>}/>
                            <Route path={"/teacher/time-table"} element={<TimeTable/>}/>
                            <Route path={"/teacher/find-student"} element={<FindStudent/>}/>
                            <Route path={"/teacher/messages"} element={<Messages/>}/>
                            <Route path={"/teacher/gallery"} element={<Gallery/>}/>
                            <Route path={"/teacher/profile"} element={<Profile/>}/>
                        </Route>
                    </Route>

                </Routes>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
