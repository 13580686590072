import DefaultInput from "../../../../../components/inputs/defaultInput";
import {SelectInput} from "../../../../../components/inputs/selectInput";
import DefaultDatePicker from "../../../../../components/inputs/defaultDatePicker";
import DefaultButton from "../../../../../components/buttons/defaultButton";
import React from "react";

const FindStudentForm = ({}) => {
    return (
        <div className={"flex flex-col gap-4"}>
            <div className={"grid grid-cols-2 gap-2"}>
                <DefaultInput label={"Student Name"}/>
                <DefaultInput label={"Student Roll Number"}/>
                <SelectInput label={"Gender"} options={[]}/>
                <DefaultDatePicker label={"DOB From"}/>
                <DefaultDatePicker label={"DOB To"}/>
                <SelectInput label={"Class Name"} options={[]}/>
                <SelectInput label={"Student Type"} options={[]}/>
                <DefaultInput label={"Keyword"}/>
            </div>
            <div>
                <DefaultButton variant="primary" name={"Submit"}/>
            </div>
        </div>
    )
}

export default FindStudentForm
