import {FiLink} from "react-icons/fi";
import {Link} from "react-router-dom";

const QuickLinks = ({item}) => {
    return (
        <Link to={""} className={"h-10 px-4 bg-gray-200 text-theme-600 rounded-lg flex items-center gap-1"}>
            <span className={"text-md"}><FiLink/></span>
            {item.name}
        </Link>
    )
}
export default QuickLinks;
