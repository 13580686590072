import React, { useEffect, useState } from "react";
import DefaultInput from "../../../../components/inputs/defaultInput";
import DefaultButton from "../../../../components/buttons/defaultButton";
import {SelectInput} from "../../../../components/inputs/selectInput";
import {PermissionsCheckbox} from "../../../../components/inputs/permissionCheckbox";
import PageTableHeader from "../../../../components/pageTableHeader/pageTableHeader";
import { useNavigate, useParams } from "react-router-dom";
import { createRole, getRoleDetail, updateRole } from "../../../../../services/workspace/role.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { getPermissions } from "../../../../../services/auth/auth.service";
import TableSkeleton from "../../../../components/skeleton/tableSkeleton";

export function AddRole({}) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [permissionList, setPermissionList] = useState()
    const [permissions, setPermissions] = useState([])

    const fetchData = ()=>{
        getPermissions().then((response)=>{
            if(!id) setLoading(false)
            setPermissionList(response.data.system_permissions)
            console.log(response.data)
        }).catch((error)=>{
            toast.error("Failed to fetch the permissions data!")
            console.log(error)
        })
    }

    const fetchDetail = ()=>{
        getRoleDetail(id).then((response)=>{
            setLoading(false)
            setData(response)
            setPermissions(response.permissions)
        }).catch((error)=>{
            toast.error("Failed to fetch the role data!")
            console.log(error)
        })
    }

    useEffect(()=>{
        fetchData()
        if(id) fetchDetail()
    },[id])

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Role Name is required'),
        display_name: Yup.string().trim().required('Role Title is required'),
        permissions: Yup.array().required('Permissions are required').min(1, 'Permissions are required') ,
    })

    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors} = useFormik({
        initialValues: {
            name: data?.name || "",
            display_name: data?.display_name || "",
            permissions: data?.permissions || [],
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values)=>{
            setIsSubmitting(true)  
            if(id){
                updateRole(values, id).then((response)=>{
                    toast.success("Role updated successfully!")
                    navigate("/basic-setup/manage-roles")
                }).catch((error)=>{
                    toast.error("Failed to update role!")
                    console.log(error)    
                })
            } else{
                createRole(values).then((response)=>{
                    toast.success("Role created successfully!")
                    navigate("/basic-setup/manage-roles")
                }).catch((error)=>{
                    toast.error("Failed to create role!")
                    console.log(error)
                })
            }
        }
    })

    const checkboxHandler = (items = []) => {
        setPermissions((prevPermissions) => {
            let updatedPermissions;
            const areAllChecked = items.every((item) => prevPermissions.includes(item));
            if (areAllChecked) {
                updatedPermissions = prevPermissions.filter((item) => !items.includes(item));
            } else {
                updatedPermissions = [...prevPermissions, ...items.filter((item) => !prevPermissions.includes(item))];
            }
            setFieldValue("permissions", updatedPermissions);
            return updatedPermissions;
        });
    };
    
    return (
        <div className={"flex flex-col"}>
            <PageTableHeader
                breadcrumbs={[
                    {label: 'Basic Setup', url: "/basic-setup"},
                    {label: 'Manage Roles',url: "/basic-setup/manage-roles"},
                    {label: id ? 'Edit' : 'Add',},
                ]}
            />
            {loading ? <TableSkeleton /> :
            <div className={"flex flex-col w-full gap-4 md:gap-8 bg-white p-4 rounded-md"}>
                <form onSubmit={handleSubmit}>
                    <div className={"flex flex-col w-full gap-2"}>
                        <div className={"grid grid-cols-3 gap-2"}>
                            <DefaultInput 
                                label={"Role Name"}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.name && errors.name ? errors.name : ''}
                            />
                            <DefaultInput 
                                label={"Role Title"}
                                name="display_name"
                                value={values.display_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.display_name && errors.display_name ? errors.display_name : ''}
                            />
                            <DefaultInput 
                                label={"Abbreviation"}
                                name="abrv"
                             />
                            <SelectInput options={[]} label={"Select Designation"}/>
                            <SelectInput options={[]} label={"Select Role"}/>
                        </div>
                    </div>

                    <div className={"grid grid-cols-1 gap-4"}>
                        {  loading ? <TableSkeleton /> :
                            <PermissionsCheckbox 
                                permissionList={permissionList} 
                                permissions={permissions} 
                                checkboxHandler={checkboxHandler}
                            />
                        }
                    </div>
                    {errors.permissions && (<p className="text-sm text-error-600 p-[3px]">{errors.permissions}</p>)}
                    <div className="my-2 px-2">
                        <DefaultButton variant="primary" name={"Submit"} type="submit" loading={isSubmitting}/>
                    </div>
                </form>
            </div>}
        </div>
    );
}
